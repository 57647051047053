/*Start information-modal*/
.information-modal{
    display: flex;
    justify-content: center !important;
}

.information-modal.warning > .modal{
    border-top: 6px solid rgb(255, 207, 0);
}

.information-modal.error > .modal{
    border-top: 6px solid rgb(237, 0, 7);
}

.information-modal.success > .modal{
    border-top: 6px solid #00884a;
}

.information-modal > .modal > .header{
    border: 0 !important;
    padding-bottom: 0 !important;
    align-items: flex-start;
    display: flex;
    font-size: var(--fs-70) !important;
}

.information-modal > .modal > .header > i{
    font-size: var(--fs-20);
    font-weight: bold !important;
}
.information-modal.success > .modal > .header > i{
    margin-left: -7px;
}
.information-modal > .modal > .content > .ui.grid{
    margin: 0;
}

.information-modal > .modal > .actions{
    border: 0 !important;
    padding-top: 0 !important;
    padding-left: var(--card-spacing) !important;
    padding-right: var(--card-spacing) !important;
}

.information-modal > .modal > .actions > button{
    min-width: 125px;
    margin: 0px !important;
}
/*End information-modal*/