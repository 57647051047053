.test-dimmer.addtocart-scheduledcoursemodal{
    justify-content: center !important;
}

/*Start addtocart-scheduledcoursemodal*/
.addtocart-scheduledcoursemodal{
    display: flex;
    justify-content: center !important;
}

.addtocart-scheduledcoursemodal .modal{
    border-top: var(--modal-border-size) solid var(--boschGreen50);
}

.addtocart-scheduledcoursemodal.warning > .modal{
    border-top: 6px solid rgb(255, 207, 0);
}

.addtocart-scheduledcoursemodal.error > .modal{
    border-top: 6px solid rgb(237, 0, 7);
}

.addtocart-scheduledcoursemodal.success > .modal{
    border-top: 6px solid #00884a;
}

.addtocart-scheduledcoursemodal > .modal > .header{
    border: 0 !important;
    padding-bottom: 0 !important;
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
    font-size: var(--fs-70) !important;
}

.addtocart-scheduledcoursemodal > .modal > .header > .close-icon{
    font-size: var(--fs-60);
    position: absolute;
    right: var(--card-spacing-sm);
    margin: 0 !important;
    cursor: pointer !important;
}

.addtocart-scheduledcoursemodal > .modal > .header > div{
    display: flex;
    align-items: center;
}
.addtocart-scheduledcoursemodal > .modal > .header > div > i{
    font-size: 1.25em;
}

.addtocart-scheduledcoursemodal > .modal > .content > .ui.grid{
    margin: 0;
}

.addtocart-scheduledcoursemodal > .modal > .actions{
    border: 0 !important;
    padding-top: 0 !important;
    display: flex;
    justify-content: flex-end;
    gap: var(--card-responsive-spacing);
}

.addtocart-scheduledcoursemodal > .modal > .actions > button{
    min-width: 125px;
    margin: 0 !important;
}


.addtocart-scheduledcoursemodal > .modal > .content table .ui.button {
    background-color: transparent;
    padding: 0;
    font-size: 1.6rem;
}

.addtocart-scheduledcoursemodal > .modal > .content table .ui.button > i {
    color: var(--boschBlue50) !important;
}

.addtocart-scheduledcoursemodal .align-right{
    text-align: right;
}
.addtocart-scheduledcoursemodal .action-cell{
    white-space: nowrap;
}
.addtocart-scheduledcoursemodal .action-cell>button{
    margin:0 !important;
    width: fit-content !important;
    height: fit-content !important;
}

.addtocart-scheduledcoursemodal .action-cell>button:last-child{
    margin-right: -0.25rem !important;
}

/* @media (max-width: 767px) {
    .addtocart-scheduledcoursemodal .modal-addCourseToCart-body-content {
        margin-top: 0px;
    }

    .addtocart-scheduledcoursemodal .modal-addCourseToCart-header div:first-child {
        margin-right: 40px;
    }
} */

@media (max-width: 767px) {
    .addtocart-scheduledcoursemodal > .modal > .header > .close-icon{
        right: var(--card-responsive-spacing);
    }
}