.right-header {
  font-weight: bold !important;
  font-size: var(--fs-normal) !important;
  padding-right: 0 !important;
}

.menu-items {
  /* padding: 0.5rem 0.5rem !important; */
  border-bottom: none !important;
  padding-bottom: 0.875rem !important;
  padding-top: 0.875rem !important;
  margin-bottom: 0 !important;
  font-family: Bosch Sans, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  color: VisitedText;
}

.ui.link.menu .item.menu-items:hover,
.ui.menu .dropdown.item.menu-items:hover,
.ui.menu .link.item.menu-items:hover,
.ui.menu a.item.menu-items:hover {
  color: #2185d0 !important;
}

.active.menu.menu-items {
  color: var(--boschBlue50) !important;
}

.ui.secondary.pointing.menu .active.item {
  font-weight: normal;
}

.ui.secondary.pointing.menu.menu-ui {
  border-bottom: none;
  position: relative;
}

.admin-options:hover {
  color: #2185d0 !important;
}

.admin-board-options {
  padding: 0.5rem !important;
  top: 1.5rem !important;
}

.ui.menu .ui.dropdown .menu > .admin-item.item:hover {
  background-color: white !important;
  color: #2185d0 !important;
}

.drop-icons-menu > i.icon,
.drop-icons-menu i.icons {
  position: absolute;
  bottom: -3px;
}

.menu-ui.menu > a:first-child {
  padding-left: 0 !important;
}

.menu-btn {
  box-shadow: none !important;
  padding-left: 0 !important;
}

.active.item.menu-items.menu-item-catalogue {
  color: var(--boschBlue50) !important;
}

@media (max-width: 991px) {
  .menu-ui {
    flex-direction: column;
  }
  .ui.secondary.pointing.menu .item {
    align-self: flex-start;
  }
}
