.modal_content_grid {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}
.row {
  padding: 0 !important;
  margin-bottom: 1rem !important;
}
.select_company,
.select_user {
  padding: 0 !important;
}
