@mixin setStyleForFlexBox(
  $direction: row,
  $justify: center,
  $align: center,
  $gap: 0rem
) {
  display: flex !important;
  flex-direction: $direction !important;
  justify-content: $justify !important;
  align-items: $align !important;
  gap: $gap !important;
  flex-wrap: nowrap;

  width: 100%;
  transition: 0.2s ease;
}

@mixin setStyleForAbsoluteElement(
  $top: 50%,
  $left: 50%,
  $transform: translate(-50%, -50%),
  $right: auto,
  $bottom: auto
) {
  position: absolute !important;
  top: $top !important;
  left: $left !important;
  right: $right;
  bottom: $bottom;
  transform: $transform !important;
}

@mixin handleOversizeText($lineClamp: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  -webkit-line-clamp: $lineClamp; /* Overwrite this if you want */
  line-clamp: $lineClamp; /* Overwrite this if you want */
}

//////////////////////////TEXT MIXINS/////////////////////////////////
@mixin text-s {
  .text-s {
    font-size: $text-s;
    line-height: $text-line-height-s;
  }
}

@mixin text-m {
  .text-m {
    font-size: $text-m;
    line-height: $text-line-height-m;
  }
}

@mixin text-l {
  .text-l {
    font-size: $text-l;
    line-height: $text-line-height-l;
  }
}

@mixin text-xl {
  .text-xl {
    font-size: $text-xl;
    line-height: $text-line-height-xl;
  }
}

@mixin text-2xl {
  .text-2xl {
    font-size: $text-2xl;
    line-height: $text-line-height-2xl;
  }
}

@mixin text-3xl {
  .text-3xl {
    font-size: $text-3xl;
    line-height: $text-line-height-3xl;
  }
}

@mixin text-4xl {
  .text-4xl {
    font-size: $text-4xl;
    line-height: $text-line-height-4xl;
  }
}

@mixin text-5xl {
  .text-5xl {
    font-size: $text-5xl;
    line-height: $text-line-height-5xl;
  }
}

@mixin text-6xl {
  .text-6xl {
    font-size: $text-6xl;
    line-height: $text-line-height-6xl;
  }
}

//////////////////////////SPACING MIXINS/////////////////////////////////

@mixin px-0 {
  padding-left: 0;
  padding-right: 0;
}

@mixin py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@mixin mr-n1 {
  margin-right: $spacing-n-m;
}

@mixin remove-space {
  padding: 0;
  margin: 0;
}

//////////////////////////TABLES MIXINS/////////////////////////////////
@mixin stripe-table {
  .stripe-table {
    table {
      tr {
        // outline: none;
        th {
          vertical-align: top;
        }
        td {
          border: none;
          vertical-align: top;
        }
      }
      tr:nth-child(even) {
        background-color: var(--bosch-gray-95);
      }
    }
  }
  :global(.-dark-mode) .stripe-table {
    table {
      tr:nth-child(even) {
        background-color: var(--bosch-gray-10);
      }
    }
  }
}

@mixin ticket-table {
  .id-column {
    width: 7rem;
    max-width: 7rem;
  }
  .service-type-column {
    width: 20rem;
    max-width: 15rem;
  }
}

//////////////////////////COMMAND BAR MIXINS/////////////////////////////////
@mixin transparent-command-bar {
  background-color: transparent !important;
  height: auto;
}

@mixin menu-icon-align-right {
  .menu-icon-align-right {
    margin-right: -1rem;
  }
}

//////////////////////////DISPLAY FLEX MIXINS/////////////////////////////////
@mixin flex-y($gap, $important: false) {
  $important-flag: if($important, " !important", "");

  display: flex#{$important-flag};
  flex-direction: column#{$important-flag};
  gap: $gap#{$important-flag};
}

@mixin flex-x($gap: 0, $important: false) {
  $important-flag: if($important, " !important", "");

  display: flex#{$important-flag};
  flex-direction: row#{$important-flag};
  justify-content: space-between#{$important-flag};
  gap: $gap#{$important-flag};
}

@mixin flex-x-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-y-center {
  @include flex-y($gap: 0);
  align-items: center;
  justify-content: center;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-x-end-center($gap: 0) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: $gap;
}

@mixin flex-inline-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

//////////////////////////BOXES MIXINS/////////////////////////////////
@mixin box-classes {
  .actions-menu {
    @include transparent-command-bar;
  }

  .actions-menu.py-0 {
    @include py-0;
  }

  .box-content {
    padding: $box-padding;
    @content;
  }

  .box-content.no-padding-top {
    padding-top: 0 !important;
  }
}

@mixin card-background {
  .card-background {
    outline: none !important;
    background-color: var(--bosch-gray-95) !important;
  }
  :global(.-dark-mode) {
    .card-background {
      background-color: var(--bosch-gray-5) !important;
    }
  }
}

@mixin card-shadow {
  .card-shadow {
    outline: none;
    background-color: transparent;
    box-shadow: 0 0 0.5rem 0.1rem var(--shadow-fill);
  }
  :global(.-dark-mode) {
    .card-shadow {
      background-color: var(--bosch-gray-5);
    }
  }
}

//////////////////////////////SCROLL BAR//////////////////////////////////
@mixin scrollbar-custom {
  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    padding-right: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #7d8389;
    border: 5px solid transparent;
    background-clip: padding-box;
    min-height: 48px;
  }
  &:-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #43464a;
    background-clip: padding-box;
    border: 3px solid transparent;
  }
}
