.landing-content-container{
  margin-bottom: 2.5rem !important;
}

.accordion.regions-language-accordion div.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.accordion.regions-language-accordion div.content {
  margin-left: 3rem;
}
.accordion.regions-language-accordion div.content a:not(:last-child):after {
  margin: 0 1rem;
  content: '|';
  color: #007BC0;
}
.accordion.regions-language-accordion i.icon{
  font-size: 30px;
  height: 100%;
}

/* .headline{
  font-size: 2rem !important;
} */

.text-center{
  text-align: center;
}

.region-name{
  font-size: 1.25rem !important;
  font-weight: 700 !important;
}

.home-img{
  margin-bottom: 3rem;
}

a.home-side-link {
  margin-bottom: 1.5rem;
}

a.home-side-link-last-element {
  margin-top: 1.5rem;
}

a.home-side-link i.icon,
a.home-side-link-last-element i.icon{
  vertical-align: sub;
}

a.home-side-link i.icon svg,
a.home-side-link-last-element i.icon svg{
  fill: #007BC0;
}

.country-label{
  background: transparent !important;
  color: var(--boschBlue50) !important;
  padding-left: 0px !important;
}

.accordion-header-text {
  font-size: 3rem !important;
}

@media (max-width: 767px){
  .title-row{
    margin-bottom: 0rem !important;
    padding-bottom: 0px !important;
  }

  .landing-body-container{
    padding-bottom: 0px !important;
  }

  .landing-image{
    margin-bottom: 0px !important;
  }

  .landing-image > img{
    left: 0px !important;
    width: 100% !important;
    height: auto !important;
    max-width: 100% !important;
    margin-bottom: 0px !important;
  }
  
  .accordion-header-text{
    font-size: 2rem !important;
    padding-top: 0px !important;
  }

  .landing-content-container{
    margin-bottom: 0px !important;
  }
}

@media (min-width: 768px){
  /* .headline{
    font-size: 4rem !important;
  } */

  .region-name{
    font-size: 1.5rem !important;
  }

  .landing-content-container{
    margin-bottom: 4rem !important;
  }
}

@media (min-width: 1152px) {
  .ui.grid.landing-content-container > div.eleven.wide.column{
    padding-left: 0rem;
    padding-right: 2rem;
  }

  .home-img{
    margin-bottom: 0px;
  }
  
  img.ui.image.home-img {
    width: 133%;
    max-width: 133%;
    left: -33%;
    height: fit-content;
  }
}