@use "../../../styles/mixins.module" as m;
@use "../../../styles/responsive.module" as r;
@use "../../../styles/zIndex.module" as zIdx;

.container {
  position: relative;

  & .account_options {
    display: none;

    &.show {
      @include m.setStyleForFlexBox(column, flex-start, flex-start);
      position: absolute;
      display: flex;
      position: absolute;
      padding: var(--card-spacing-sm);
      filter: drop-shadow(0 0 4px #00000040);
      z-index: zIdx.$lang_selection_box_z_idx;
      background-color: var(--boschWhite);

      width: 15.625rem !important;
      left: 0;
      bottom: 100%;

      & .header {
        @include m.setStyleForFlexBox(row, space-between);
        margin-bottom: var(--card-spacing-sm);

        & > i {
          color: var(--boschBlack) !important;
          cursor: pointer;

          &:hover {
            color: var(--boschBlue50) !important;
          }
        }
      }

      & .title {
        color: var(--boschBlack);
        font-weight: bold;
        margin: 0 !important;
      }

      & .options_list {
        @include m.setStyleForFlexBox(column, flex-start, flex-start);

        & .option {
          width: 100%;

          & a:hover {
            background-color: var(--boschGray90) !important;
          }

          & i,
          & span {
            color: var(--boschBlack) !important;
          }
        }
      }
    }
  }
}

@include r.laptop_s_screen_and_up {
  .container {
    display: none !important;

    // & .account_options {
    //   &.show {
    //     left: calc(100% + var(--card-spacing-sm)) !important;

    //     &::before {
    //       content: "";
    //       position: absolute;
    //       left: calc(-1 * var(--card-spacing-sm));
    //       width: calc(var(--card-spacing-sm) * 2);
    //       height: calc(100% + 3rem);
    //       background-color: transparent;

    //       top: 0;
    //     }
    //   }
    // }
  }
}
