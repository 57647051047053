/*Start welcome_cmd3_modal*/
.welcome_cmd3_modal{
    display: flex;
    justify-content: center !important;
}

.welcome_cmd3_modal.warning > .modal{
    border-top: 6px solid var(--boschYellow85);
}

.welcome_cmd3_modal.error > .modal{
    border-top: 6px solid var(--boschRed50);
}

.welcome_cmd3_modal.success > .modal{
    border-top: 6px solid var(--boschGreen50);
}

.welcome_cmd3_modal > .modal > .header{
    border: 0 !important;
    padding-bottom: 0 !important;
    align-items: center;
    display: flex;
    font-size: var(--fs-70) !important;
    font-family: var(--boschFont) !important;
}

.welcome_cmd3_modal > .modal > .header > i{
    font-size: 2rem;
    margin-top: -3px;
    padding-top: 5px;
}

.welcome_cmd3_modal > .modal > .content > .ui.grid{
    margin: 0;
    font-size: .8rem;
}

.welcome_cmd3_modal > .modal > .content > .ui.grid > .row{
    flex-direction: column;
}

.welcome_cmd3_modal > .modal > .content > .ui.grid .activation_link{
    display: flex;
    flex-direction: column;
    margin-bottom: var(--fs-60);
}

.welcome_cmd3_modal > .modal > .content > .ui.grid .activation_link a {
    text-decoration: underline;
    font-size: medium;
    font-weight: bold;
}

.welcome_cmd3_modal > .modal > .content > .ui.grid small.activation_link_notes{
    font-size: 80%;
}

.content{
    padding: var(--fs-70) !important;
    
}

.content > .ui.grid > .row > p{ 
    color: var(--boschBlack) !important;
}

.welcome_cmd3_modal > .modal > .actions{
    border: 0 !important;
    padding-top: 0 !important;
    background: var(--boschSilver) !important;
}

.welcome_cmd3_modal > .modal > .actions > button{
    font-weight: normal !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    font-family: var(--boschFont) !important;
}

.description_text > a{
    text-decoration: underline;
}
/*End welcome_cmd3_modal*/