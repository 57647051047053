.rd_text {
  margin: 0 !important;
}

._s {
  font-size: var(--text-s) !important;
  line-height: 1.5rem !important;
}

._s.fit_content {
  line-height: var(--text-s) !important;
}

._m {
  font-size: var(--text-m) !important;
  line-height: 1.5rem !important;
}

._m.fit_content {
  line-height: var(--text-m) !important;
}

._l {
  font-size: var(--text-l) !important;
  line-height: 1.5rem !important;
}

._l.fit_content {
  line-height: var(--text-l) !important;
}

._xl {
  font-size: var(--text-xl) !important;
  line-height: 1.4rem !important;
}

._xl.fit_content {
  line-height: var(--text-xl) !important;
}

._2xl {
  font-size: var(--text-2xl) !important;
  line-height: 1.4rem !important;
}

._2xl.fit_content {
  line-height: var(--text-2xl) !important;
}

._3xl {
  font-size: var(--text-3xl) !important;
  line-height: 1.3rem !important;
}

._3xl.fit_content {
  line-height: var(--text-3xl) !important;
}

._4xl {
  font-size: var(--text-4xl) !important;
  line-height: 1.25rem !important;
}

._4xl.fit_content {
  line-height: var(--text-4xl) !important;
}

._5xl {
  font-size: var(--text-5xl) !important;
  line-height: 1.2rem !important;
}

._5xl.fit_content {
  line-height: var(--text-5xl) !important;
}

._6xl {
  font-size: var(--text-6xl) !important;
  line-height: 1.15rem !important;
}

._6xl.fit_content {
  line-height: var(--text-6xl) !important;
}
