.landing_wrapper{
  padding-top: 0 !important;
}

.banner_section{
  margin: 0 !important;
}

.country_selector{
  background: var(--boschGray95);
  margin: 0 !important;
  padding-top: 0 !important;
  padding-left: 10rem !important;
  padding-right: 10rem !important;
  padding-bottom: 10rem !important;
}

.country_selector > div:first-child > h3{
  margin: 0 !important;
  padding-top: 1rem !important;
  font-size: 2rem !important;
  font-weight: bold;
}

.country_selector > div:nth-child(2){
  display: flex !important;
  width: 100%;
  margin: 0 !important;
  padding-left: 1rem !important;
  padding-top: 0.5rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0.5rem !important;
  background: var(--boschWhite);
}

.country_selector > div:nth-child(2) > div{
  width: 100% !important;
  padding: 0 !important;
}

.country_selector > div:nth-child(2) > div > div > h4{
  font-size: 1.2rem !important;
  font-weight: bold;
  color: var(--boschBlue50) !important;
  margin: 0 !important;
}

.country_selector > div:nth-child(2) > div > div{
  padding: 0 !important;
  margin: 0 !important;
  line-height: 31.4px !important;
}

.country_selector > div:nth-child(2) > div > div:nth-child(3n){
  margin-right: 0.85rem !important;
  margin-top: 0.35rem !important;
  margin-bottom: 0.25rem !important;
}

.countries_content{
  padding: 0 !important;
  padding-left: 0.5rem !important;
  margin: 0 !important;
  display: flex !important;
  flex-flow: row;
}

.countries_content > div {
  padding: 0.5rem !important;
}

@media only screen and (max-width: 767.99px){
  
  .country_selector{   
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .country_selector > div:first-child > h3{
    margin: 0 !important;
    padding-top: 1rem !important;
    font-size: 1rem !important;
    font-weight: bold;
  }
  
}
