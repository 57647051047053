@use "./styles/reset";
@use "./styles/frok";

:root {
  --boschWhite: #ffffff;
  --boschSilver: #eff1f2;
  --boschBlack: #000000;
  --shadow-fill: #00000040;
  --boschBlack20: rgba(34, 36, 38, 0.15);
  --boschBlack10: rgba(0, 0, 0, 0.87);
  --boschPurple50: #c535bc;

  --boschBlue95: #e8f1ff;
  --boschBlue90: #d1e4ff;
  --boschBlue85: #b8d6ff;
  --boschBlue80: #9dc9ff;
  --boschBlue75: #7ebdff;
  --boschBlue70: #56b0ff;
  --boschBlue65: #00a4fd;
  --boschBlue60: #0096e8;
  --boschBlue55: #0088d4;
  --boschBlue50: #007bc0;
  --boschBlue45: #006ead;
  --boschBlue40: #00629a;
  --boschBlue35: #005587;
  --boschBlue30: #004975;
  --boschBlue25: #003e64;
  --boschBlue20: #003253;
  --boschBlue15: #002742;
  --boschBlue10: #001d33;
  --boschBlue5: #001222;

  --boschRed95: #ffecec;
  --boschRed90: #ffd9d9;
  --boschRed85: #ffc6c6;
  --boschRed80: #ffb2b2;
  --boschRed75: #ff9d9d;
  --boschRed70: #ff8787;
  --boschRed65: #ff6e6f;
  --boschRed60: #ff5152;
  --boschRed55: #ff2124;
  --boschRed50: #ed0007;
  --boschRed45: #d50005;
  --boschRed40: #be0004;
  --boschRed35: #a80003;
  --boschRed30: #920002;
  --boschRed25: #7d0002;
  --boschRed20: #680001;
  --boschRed15: #540001;
  --boschRed10: #410000;
  --boschRed5: #2d0000;

  --boschGreen95: #e2f5e7;
  --boschGreen90: #b8efc9;
  --boschGreen85: #9be4b3;
  --boschGreen80: #86d7a2;
  --boschGreen75: #72ca92;
  --boschGreen70: #5ebd82;
  --boschGreen65: #4ab073;
  --boschGreen60: #37a264;
  --boschGreen55: #219557;
  --boschGreen50: #00884a;
  --boschGreen45: #007a42;
  --boschGreen40: #006c3a;
  --boschGreen35: #005f32;
  --boschGreen30: #00512a;
  --boschGreen25: #004523;
  --boschGreen20: #00381b;
  --boschGreen15: #002c14;
  --boschGreen10: #00210e;
  --boschGreen5: #001507;

  --boschYellow95: #ffefd1;
  --boschYellow90: #ffdf95;
  --boschYellow85: #ffcf00;
  --boschYellow80: #eec100;
  --boschYellow75: #deb300;
  --boschYellow70: #cda600;
  --boschYellow65: #bd9900;
  --boschYellow60: #ad8c00;
  --boschYellow55: #9e7f00;
  --boschYellow50: #8f7300;
  --boschYellow45: #806700;
  --boschYellow40: #725b00;
  --boschYellow35: #644f00;
  --boschYellow30: #564400;
  --boschYellow25: #493900;
  --boschYellow20: #3c2e00;
  --boschYellow15: #2f2400;
  --boschYellow10: #231a00;
  --boschYellow5: #171000;

  --boschGray100: #d9d9d9;
  --boschGray95: #eff1f2;
  --boschGray90: #e0e2e5;
  --boschGray85: #d0d4d8;
  --boschGray80: #c1c7cc;
  --boschGray75: #b2b9c0;
  --boschGray70: #a4abb3;
  --boschGray65: #979ea4;
  --boschGray60: #8a9097;
  --boschGray55: #7d8389;
  --boschGray50: #71767c;
  --boschGray45: #656a6f;
  --boschGray40: #595e62;
  --boschGray35: #4e5256;
  --boschGray30: #43464a;
  --boschGray25: #383b3e;
  --boschGray20: #2e3033;
  --boschGray15: #232628;
  --boschGray10: #1a1c1d;
  --boschGray5: #101112;

  --boschGray20-gradient10: rgb(46, 48, 51, 0.1);

  --boschTurquoise95: #def5f3;
  --boschTurquoise90: #b6ede8;
  --boschTurquoise85: #a1dfdb;
  --boschTurquoise80: #8dd2cd;
  --boschTurquoise75: #79c5c0;
  --boschTurquoise70: #66b8b2;
  --boschTurquoise65: #54aba5;
  --boschTurquoise60: #419e98;
  --boschTurquoise55: #2e908b;
  --boschTurquoise50: #18837e;
  --boschTurquoise45: #147671;
  --boschTurquoise40: #116864;
  --boschTurquoise35: #0e5b57;
  --boschTurquoise30: #0a4f4b;
  --boschTurquoise25: #07423f;
  --boschTurquoise20: #053634;
  --boschTurquoise15: #032b28;
  --boschTurquoise10: #02201e;
  --boschTurquoise5: #011413;

  --boschPurple95: #f7eef6;
  --boschPurple90: #f0dcee;
  --boschPurple85: #ebcae8;
  --boschPurple80: #e8b6e3;
  --boschPurple75: #e5a2df;
  --boschPurple70: #e48cdd;
  --boschPurple65: #e472db;
  --boschPurple60: #e552da;
  --boschPurple55: #d543cb;
  --boschPurple50: #c535bc;
  --boschPurple45: #b12ea9;
  --boschPurple40: #9e2896;
  --boschPurple35: #8b2284;
  --boschPurple30: #791d73;
  --boschPurple25: #671761;
  --boschPurple20: #551151;
  --boschPurple15: #440c41;
  --boschPurple10: #340731;
  --boschPurple5: #230421;

  --background: var(--boschWhite);
  --plain__enabled__fill__default: #0000;
  --plain__enabled__fill__hovered: var(--boschGray90);
  --plain__enabled__fill__pressed: var(--boschGray80);
  --plain__enabled__front__default: var(--boschBlack);
  --plain__enabled__front__hovered: var(--boschBlack);
  --plain__enabled__front__pressed: var(--boschBlack);
  --plain__focused__fill__default: var(--boschBlue95);
  --plain__focused__front__default: var(--boschBlack);
  --plain__disabled__fill__default: #0000;
  --plain__disabled__front__default: var(--boschGray80);
  --neutral__enabled__fill__default: var(--boschGray90);
  --neutral__enabled__fill__hovered: var(--boschGray80);
  --neutral__enabled__fill__pressed: var(--boschGray70);
  --neutral__enabled__front__default: var(--boschBlack);
  --neutral__enabled__front__hovered: var(--boschBlack);
  --neutral__enabled__front__pressed: var(--boschBlack);
  --neutral__focused__fill__default: var(--boschBlue90);
  --neutral__focused__front__default: var(--boschBlack);
  --neutral__disabled__fill__default: var(--boschGray90);
  --neutral__disabled__front__default: var(--boschGray70);
  --small__enabled__fill__default: var(--boschGray60);
  --small__enabled__fill__hovered: var(--boschGray50);
  --small__enabled__fill__pressed: var(--boschGray40);
  --small__enabled__front__default: var(--boschWhite);
  --small__enabled__front__hovered: var(--boschWhite);
  --small__enabled__front__pressed: var(--boschWhite);
  --small__disabled__fill__default: var(--boschGray90);
  --small__disabled__front__default: var(--boschGray70);
  --integrated__enabled__fill__default: #0000;
  --integrated__enabled__fill__hovered: #0000;
  --integrated__enabled__fill__pressed: #0000;
  --integrated__enabled__front__default: var(--boschBlack);
  --integrated__enabled__front__hovered: var(--boschBlue50);
  --integrated__enabled__front__pressed: var(--boschBlue40);
  --integrated__disabled__fill__default: #0000;
  --integrated__disabled__front__default: var(--boschGray80);
  --minor-accent__enabled__fill__default: #0000;
  --minor-accent__enabled__fill__hovered: var(--boschBlue90);
  --minor-accent__enabled__fill__pressed: var(--boschBlue80);
  --minor-accent__enabled__front__default: var(--boschBlue50);
  --minor-accent__enabled__front__hovered: var(--boschBlue40);
  --minor-accent__enabled__front__pressed: var(--boschBlue30);
  --minor-accent__disabled__fill__default: #0000;
  --minor-accent__disabled__front__default: var(--boschGray80);
  --major-accent__enabled__fill__default: var(--boschBlue50);
  --major-accent__enabled__fill__hovered: var(--boschBlue40);
  --major-accent__enabled__fill__pressed: var(--boschBlue30);
  --major-accent__enabled__front__default: var(--boschWhite);
  --major-accent__enabled__front__hovered: var(--boschWhite);
  --major-accent__enabled__front__pressed: var(--boschWhite);
  --major-accent__disabled__fill__default: var(--boschGray80);
  --major-accent__disabled__front__default: var(--boschGray60);
  --minor-signal-neutral__enabled__fill__default: var(--boschBlue90);
  --minor-signal-neutral__enabled__fill__hovered: var(--boschBlue80);
  --minor-signal-neutral__enabled__fill__pressed: var(--boschBlue70);
  --minor-signal-neutral__enabled__front__default: var(--boschBlack);
  --minor-signal-neutral__enabled__front__hovered: var(--boschBlack);
  --minor-signal-neutral__enabled__front__pressed: var(--boschBlack);
  --minor-signal-error__enabled__fill__default: var(--boschRed90);
  --minor-signal-error__enabled__fill__hovered: var(--boschRed80);
  --minor-signal-error__enabled__fill__pressed: var(--boschRed70);
  --minor-signal-error__enabled__front__default: var(--boschBlack);
  --minor-signal-error__enabled__front__hovered: var(--boschBlack);
  --minor-signal-error__enabled__front__pressed: var(--boschBlack);
  --minor-signal-warning__enabled__fill__default: var(--boschYellow90);
  --minor-signal-warning__enabled__fill__hovered: var(--boschYellow80);
  --minor-signal-warning__enabled__fill__pressed: var(--boschYellow70);
  --minor-signal-warning__enabled__front__default: var(--boschBlack);
  --minor-signal-warning__enabled__front__hovered: var(--boschBlack);
  --minor-signal-warning__enabled__front__pressed: var(--boschBlack);
  --minor-signal-success__enabled__fill__default: var(--boschGreen90);
  --minor-signal-success__enabled__fill__hovered: var(--boschGreen80);
  --minor-signal-success__enabled__fill__pressed: var(--boschGreen70);
  --minor-signal-success__enabled__front__default: var(--boschBlack);
  --minor-signal-success__enabled__front__hovered: var(--boschBlack);
  --minor-signal-success__enabled__front__pressed: var(--boschBlack);
  --major-signal-neutral__enabled__fill__default: var(--boschBlue50);
  --major-signal-neutral__enabled__fill__hovered: var(--boschBlue40);
  --major-signal-neutral__enabled__fill__pressed: var(--boschBlue30);
  --major-signal-neutral__enabled__front__default: var(--boschWhite);
  --major-signal-neutral__enabled__front__hovered: var(--boschWhite);
  --major-signal-neutral__enabled__front__pressed: var(--boschWhite);
  --major-signal-error__enabled__fill__default: var(--boschRed50);
  --major-signal-error__enabled__fill__hovered: var(--boschRed40);
  --major-signal-error__enabled__fill__pressed: var(--boschRed30);
  --major-signal-error__enabled__front__default: var(--boschWhite);
  --major-signal-error__enabled__front__hovered: var(--boschWhite);
  --major-signal-error__enabled__front__pressed: var(--boschWhite);
  --major-signal-warning__enabled__fill__default: var(--boschYellow85);
  --major-signal-warning__enabled__fill__hovered: var(--boschYellow75);
  --major-signal-warning__enabled__fill__pressed: var(--boschYellow65);
  --major-signal-warning__enabled__front__default: var(--boschBlack);
  --major-signal-warning__enabled__front__hovered: var(--boschBlack);
  --major-signal-warning__enabled__front__pressed: var(--boschBlack);
  --major-signal-success__enabled__fill__default: var(--boschGreen50);
  --major-signal-success__enabled__fill__hovered: var(--boschGreen40);
  --major-signal-success__enabled__fill__pressed: var(--boschGreen30);
  --major-signal-success__enabled__front__default: var(--boschWhite);
  --major-signal-success__enabled__front__hovered: var(--boschWhite);
  --major-signal-success__enabled__front__pressed: var(--boschWhite);
  --minor-highlight-purple__enabled__fill__default: #0000;
  --minor-highlight-purple__enabled__fill__hovered: var(--boschPurple90);
  --minor-highlight-purple__enabled__fill__pressed: var(--boschPurple80);
  --minor-highlight-purple__enabled__front__default: var(--boschPurple40);
  --minor-highlight-purple__enabled__front__hovered: var(--boschPurple30);
  --minor-highlight-purple__enabled__front__pressed: var(--boschPurple20);
  --minor-highlight-blue__enabled__fill__default: #0000;
  --minor-highlight-blue__enabled__fill__hovered: var(--boschBlue90);
  --minor-highlight-blue__enabled__fill__pressed: var(--boschBlue80);
  --minor-highlight-blue__enabled__front__default: var(--boschBlue50);
  --minor-highlight-blue__enabled__front__hovered: var(--boschBlue40);
  --minor-highlight-blue__enabled__front__pressed: var(--boschBlue30);
  --minor-highlight-turquoise__enabled__fill__default: #0000;
  --minor-highlight-turquoise__enabled__fill__hovered: var(--boschTurquoise90);
  --minor-highlight-turquoise__enabled__fill__pressed: var(--boschTurquoise80);
  --minor-highlight-turquoise__enabled__front__default: var(--boschTurquoise50);
  --minor-highlight-turquoise__enabled__front__hovered: var(--boschTurquoise40);
  --minor-highlight-turquoise__enabled__front__pressed: var(--boschTurquoise30);
  --minor-highlight-green__enabled__fill__default: #0000;
  --minor-highlight-green__enabled__fill__hovered: var(--boschGreen90);
  --minor-highlight-green__enabled__fill__pressed: var(--boschGreen80);
  --minor-highlight-green__enabled__front__default: var(--boschGreen50);
  --minor-highlight-green__enabled__front__hovered: var(--boschGreen40);
  --minor-highlight-green__enabled__front__pressed: var(--boschGreen30);
  --major-highlight-purple__enabled__fill__default: var(--boschPurple40);
  --major-highlight-purple__enabled__fill__hovered: var(--boschPurple30);
  --major-highlight-purple__enabled__fill__pressed: var(--boschPurple20);
  --major-highlight-purple__enabled__front__default: var(--boschWhite);
  --major-highlight-purple__enabled__front__hovered: var(--boschWhite);
  --major-highlight-purple__enabled__front__pressed: var(--boschWhite);
  --major-highlight-blue__enabled__fill__default: var(--boschBlue50);
  --major-highlight-blue__enabled__fill__hovered: var(--boschBlue40);
  --major-highlight-blue__enabled__fill__pressed: var(--boschBlue30);
  --major-highlight-blue__enabled__front__default: var(--boschWhite);
  --major-highlight-blue__enabled__front__hovered: var(--boschWhite);
  --major-highlight-blue__enabled__front__pressed: var(--boschWhite);
  --major-highlight-turquoise__enabled__fill__default: var(--boschTurquoise50);
  --major-highlight-turquoise__enabled__fill__hovered: var(--boschTurquoise40);
  --major-highlight-turquoise__enabled__fill__pressed: var(--boschTurquoise30);
  --major-highlight-turquoise__enabled__front__default: var(--boschWhite);
  --major-highlight-turquoise__enabled__front__hovered: var(--boschWhite);
  --major-highlight-turquoise__enabled__front__pressed: var(--boschWhite);
  --major-highlight-green__enabled__fill__default: var(--boschGreen50);
  --major-highlight-green__enabled__fill__hovered: var(--boschGreen40);
  --major-highlight-green__enabled__fill__pressed: var(--boschGreen30);
  --major-highlight-green__enabled__front__default: var(--boschWhite);
  --major-highlight-green__enabled__front__hovered: var(--boschWhite);
  --major-highlight-green__enabled__front__pressed: var(--boschWhite);

  --BATS-max-width: 1300px;

  --boschFont: Bosch Sans, "Helvetica Neue", Helvetica, Arial, sans-serif !important;

  --card-spacing-xl: 3rem;
  --card-spacing: 1.5rem;
  --card-spacing-sm: 1rem;
  --card-spacing-xs: 0.75rem;
  --card-spacing-lg: 2rem;
  --card-spacing-xl: 2.5rem;
  --card-spacing-horizontal: 1.5rem 1rem;
  --card-spacing-vertical: 1rem 1.5rem;
  --card-spacing-sm-horizontal: 1rem 0.5rem;
  --card-spacing-sm-vertical: 0.5rem 1rem;
  --card-spacing-xs-horizontal: 1.25rem 0.75rem;
  --card-spacing-xs-vertical: 0.75rem 1.25rem;
  --card-border-radius: 0;
  --card-spacing-n-xl: -3rem;
  --card-spacing-n: -1.5rem;
  --card-responsive-spacing: 0.5rem;
  --card-tab-height: 2.1875rem;

  --input-border-radius: 0.25rem;
  --modal-border-size: 6px;

  --button-min-width: 125px;

  --fs-10: 4rem; /*64px: For header of home page*/
  --fs-20: 2.5rem; /*40px*/
  --fs-30: 2.25rem; /*36px For section header*/
  --fs-40: 2rem; /*32px*/
  --fs-50: 1.75rem; /*28px*/
  --fs-60: 1.5rem; /*24px*/
  --fs-70: 1.25rem; /*20px*/
  --fs-75: 1.125rem; /*18px Modal header*/
  --fs-77: 0.875rem; /*14px Modal header*/
  --fs-80: 0.813rem; /*13px small text*/
  --fs-normal: 1rem; /*16px small text*/
  --fs-90: 0.75rem;
  --fs-100: 0.625rem; /*10px small text*/

  --text-s: 0.75rem;
  --text-m: 1rem;
  --text-l: 1.25rem;
  --text-xl: 1.5rem;
  --text-2xl: 2rem;
  --text-3xl: 2.5rem;
  --text-4xl: 3rem;
  --text-5xl: 4rem;
  --text-6xl: 5rem;

  --dashboard-icon-height: 6rem;
  --dashboard-icon-margin: 0.9375rem;

  --CDM3-max-width: 1300px;
  --CDM2-max-width: 1120px;

  --footer-bgr-clr: var(--boschGray20); /* Footer background color */
  --footer-content-clr: var(--boschWhite);
  --footer-link-hovered-clr: var(--boschBlue70);
  --footer-language-dropdown-bgr-clr: var(--boschGray30);
  --footer-language-dropdown-selected-item-bgr-clr: var(--boschGray35);
  --footer-icon-share-size: var(--fs-60);
}


:root {
  --floating-background-shadow: 0 0 0.5rem 0.1rem
    var(--floating-background-color);
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --box-arrow-size: 1rem;
}

:root {
  --rd-table-bgr-color: var(--boschWhite);
  --rd-table-cell-padding: 0.75rem 1rem;
  --rd-table-cell-padding-tablet: 0.5rem 0.75rem;
  --rd-table-header-row-frg-color: var(--boschBlack);
  --rd-table-header-row-border-color: var(--boschBlack);
  --rd-table-basic-row-border-color: var(--boschGray80);
  --rd-table-cell-min-height: 3rem;
  --rd-table-content-font-size: var(--text-m);
}

:root {
  --rd-button-min-width: 9.375rem; /* 9.375rem = 150px */
  --rd-button-small-min-width: 7.8125rem; /* 7.8125rem = 125px */
  --rd-button-padding: 0.75rem 1rem;
  --rd-button-small-padding: 0.5rem 0.75rem;
  --rd-button-bgr-color: var(--boschBlue50);
  --dashboard-item-base-width: 10.625rem;
  --dashboard-item-base-height: 9.0625rem;
}

:root {
  --table-background-color: var(--boschGray95);
  --table-mobile-background-color: var(--boschWhite);
  --table-header-border-color: var(--boschGray90);
}


*:not(i) {
  font-family: var(--boschFont) !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.headline {
  font-size: 28px !important;
  margin-bottom: 0.5rem !important;
  text-align: left !important;
}

.headline-description {
  font-size: 1rem !important;
  font-weight: 500;
}

.headline-subtitle {
  font-size: 2rem !important;
  text-align: left !important;
}

.acticle-title {
  font-size: 1.25rem !important;
  margin-bottom: 12px !important;
  line-height: 1.2 !important;
  font-weight: 700 !important;
}

.ui.divider {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.back-button {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 1.25rem !important;
}

.p-0,
.ui.stackable.grid > .row > .column.p-0,
.ui.stackable.grid > .row > .wide.column.p-0 {
  padding: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.main-headline {
  font-size: 2rem !important;
}

.test-dimmer {
  background-color: rgba(0, 0, 0, 0.85) !important;
}

.custom-popup.ui.popup {
  font-size: 0.7rem;
}
.custom-popup.ui.popup:before {
  box-shadow: none !important;
}

.button-block {
  width: 100% !important;
}

.text_oversize_handler {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  -webkit-line-clamp: 3; /* Overwrite this if you want */
  line-clamp: 3; /* Overwrite this if you want */
}

/*Checkbox*/
.ui.checkbox.filter-tickbox input:checked ~ label:after {
  content: "" !important;
}

.ui.checkbox.filter-tickbox input:checked ~ label::after {
  content: "";
  box-sizing: border-box;
  border-bottom: 2px solid var(--boschBlue50) !important;
  border-left: 2px solid var(--boschBlue50) !important;
  position: absolute;
  left: 5px;
  top: 3px;
  -webkit-transform: rotate(-44.5deg);
  transform: rotate(-44.5deg);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  width: 10px;
  height: 6px;
  color: var(--boschBlue50) !important;
}

.ui.checkbox.filter-tickbox.checked label {
  color: var(--boschBlue50) !important;
}
.ui.checkbox.filter-tickbox.checked label:before,
.ui.checkbox.filter-tickbox label:before {
  border-radius: 0 !important;
}
/*End checkbox*/

/*Semantic customization*/
.ui.card > .content > .description,
.ui.cards > .card > .content > .description {
  color: var(--boschBlack) !important;
}
button.ui.button {
  font-weight: normal;
}
/*End Semantic customization*/

@media (min-width: 768px) {
  .main-headline {
    font-size: 64px !important;
  }

  .headline {
    font-size: 36px !important;
  }

  .headline-subtitle {
    font-size: 36px !important;
  }

  .acticle-title {
    font-size: 1.25rem;
    margin-bottom: 12px;
    line-height: 1.2;
  }

  .headline-description {
    font-size: 1.25rem !important;
  }

  .back-button {
    font-size: 2rem;
  }
}

.table-responsive tr > td > .data-cell > label {
  display: none;
}

.fixed-table {
  table-layout: fixed !important;
}

@media (max-width: 991px) {
  #root {
    overflow-x: hidden !important;
  }
}

@media (max-width: 767px) {
  .table-responsive thead {
    display: none !important;
  }
  .table-responsive.unstackable tr {
    display: block;
    margin-bottom: 1rem;
  }
  .table-responsive.unstackable tr > td {
    display: block;
    padding: 0px !important;
    margin-bottom: 0.25rem;
  }
  .table-responsive tr > td > .data-cell {
    font-weight: normal;
  }
  .table-responsive tr > td > .data-cell > label {
    display: inline-block;
  }
}
