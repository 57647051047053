.program_items_table_wrapper {
  padding: 0 !important;
}

.program_items_table {
  width: 100% !important;
  border: 0 !important;
  background: var(--boschGray95);
  margin-bottom: 1rem !important;
}

.table_header {
  background: var(--boschGray95);
}

.table_header > tr > td {
  padding-left: var(--card-responsive-spacing) !important;
  border-bottom: 1px solid var(--boschGray70) !important;
}

.table_header > tr > td:first-child {
  padding: var(--card-spacing-sm) !important;
}

.table_body > tr > td:first-child {
  padding-left: var(--card-spacing-sm) !important;
}

.table_body > tr > td:last-child {
  padding-right: var(--card-spacing-sm) !important;
}

.table_body > tr:first-child > td {
  padding-top: var(--card-spacing-sm) !important;
}

.table_body > tr:last-child > td {
  padding-bottom: var(--card-spacing-sm) !important;
}

.program_items_table > tbody > tr {
  background: var(--boschGray95) !important;
  padding-left: var(--card-responsive-spacing) !important;
  border: none !important;
}

.program_items_table > tbody > tr > td {
  background: var(--boschGray95) !important;
  padding: 0.25rem 0.125rem 0.125rem var(--card-responsive-spacing) !important;
  border: none !important;
}

.action_wraper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin: 0 !important;
  align-content: center !important;
}
.view_button {
  color: var(--boschWhite) !important;
  display: flex !important;
  flex-direction: row-reverse;
  gap: 0.2rem;
  font-size: var(--fs-77) !important;
  padding-right: 0.5rem !important;
}

.view_button > i {
  margin: 0 !important;
  color: var(--boschWhite) !important;
}

.action_wraper > .program_item_action_icon {
  border: none !important;
  margin: 0 !important;
  font-size: var(--fs-50) !important;
  padding: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
  color: var(--boschBlue50) !important;
  display: flex !important;
}
.action_wraper > .program_item_action_icon > i {
  margin: 0 !important;
}

.action_wraper > .program_item_action_icon > i:hover {
  color: var(--boschBlue40) !important;
}

.action_wraper > .program_item_action_icon.add_to_cart > i {
  margin-top: -2px !important;
}
.disabled {
  opacity: 0.5;
}

.registered {
  /* background: red !important; */
}

.completed,
.merit {
  color: var(--boschGreen50);
}
.failed {
  color: var(--boschRed50);
}

.program_title {
  display: flex !important;
  align-items: center;
}
.program_title > span {
  margin-left: -6px !important;
}
@media (min-width: 768px) {
  .mobile_header {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .mobile_header {
    display: none !important;
  }
  .training_program_status_wrapper {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media (max-width: 768px) {
  .training_program_title_wrapper {
    flex-direction: column !important;
    gap: 0.3rem;
  }
  .training_program_title_wrapper > * {
    margin-left: 0 !important;
  }
  .training_program_desciption_icon {
    display: none;
  }
}

@media (max-width: 575px) {
  .table_header,
  .trainning_program_cateicon {
    display: none !important;
  }

  .program_items_table > tbody > tr > td {
    background: var(--boschWhite) !important;
    display: flex !important;
  }
  .program_items_table > tbody > tr:first-child {
    padding-top: var(--card-spacing-sm) !important;
    margin-right: var(--card-responsive-spacing) !important;
  }
  .program_items_table > tbody > tr:last-child {
    padding-bottom: var(--card-spacing-sm) !important;
  }
  .table_body {
    background-color: var(--boschGray95) !important;
  }
  .table_body > tr > td:first-child > i {
    display: none;
  }
  .program_items_table > .table_body > tr:not(:first-child) {
    margin-top: var(--card-spacing-sm);
    margin-right: var(--card-responsive-spacing) !important;
    margin-left: var(--card-responsive-spacing) !important;
    display: flex !important;
    flex-direction: column;
  }
  .program_items_table > .table_body > tr:first-child,
  .program_items_table > .table_body > tr:last-child {
    background: var(--boschGray95) !important;
  }

  .program_items_table > .table_body > tr > td {
    padding-left: var(--card-responsive-spacing) !important;
    padding-bottom: 0 !important;
  }
  .program_items_table > .table_body > tr > td:last-child {
    padding-bottom: var(--card-responsive-spacing) !important;
  }
  .table_body > tr:first-child > td {
    padding-top: 0 !important;
  }
  .table_body > tr > td:first-child {
    padding-left: var(--card-responsive-spacing) !important;
  }
  .program_items_table > tbody > tr {
    background: white !important;
    margin-left: var(--card-responsive-spacing) !important;
    padding-left: 0 !important;
    display: flex !important;
    flex-direction: column;
  }
  .table_body,
  .table_body > tr {
    display: block;
  }
}
