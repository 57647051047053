/*Start course-withdraw-modal-wrapper*/
.course-withdraw-modal-wrapper{
  display: flex;
  justify-content: center !important;
}

.course-withdraw-modal-wrapper.warning > .modal{
  border-top: 6px solid rgb(255, 207, 0);
}

.course-withdraw-modal-wrapper.error > .modal{
  border-top: 6px solid rgb(237, 0, 7);
}

.course-withdraw-modal-wrapper.success > .modal{
  border-top: 6px solid #00884a;
}

.course-withdraw-modal-wrapper > .modal > .header{
  border: 0 !important;
  padding-bottom: 0 !important;
  align-items: center;
  display: flex;
}

.course-withdraw-modal-wrapper > .modal > .header > i{
  font-size: 1.25em;
}

.course-withdraw-modal-wrapper > .modal > .content > .ui.grid{
  margin: 0;
}

.course-withdraw-modal-wrapper > .modal > .actions{
  border: 0 !important;
  padding-top: 0 !important;
  display: flex !important;
  justify-content: flex-end;
  gap: var(--card-responsive-spacing);
}

.course-withdraw-modal-wrapper > .modal > .actions > button{
  min-width: var(--button-min-width);
  margin: 0 !important;

}
/*End course-withdraw-modal-wrapper*/