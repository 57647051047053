@use "../../../styles/mixins.module" as m;
@use "../../../styles/responsive.module" as r;
@use "../../../styles/zIndex.module" as zIdx;

.container {
  position: relative;

  & .account_options {
    display: none;

    &.show {
      @include m.setStyleForFlexBox(column, flex-start, flex-start);
      position: absolute;
      display: flex;
      position: absolute;
      padding: var(--card-spacing-sm);
      filter: drop-shadow(0 0 4px #00000040);
      z-index: zIdx.$lang_selection_box_z_idx;
      background-color: var(--boschWhite);
      transform: translateX(-50%);

      width: 15.625rem !important;
      left: 50%;
      top: 110%;

      & .header {
        @include m.setStyleForFlexBox(row, space-between);
        margin-bottom: var(--card-spacing-sm);

        & > i {
          color: var(--boschBlack) !important;
          cursor: pointer;

          &:hover {
            color: var(--boschBlue50) !important;
          }
        }
      }

      & .title {
        color: var(--boschBlack);
        font-weight: bold;
        margin: 0 !important;
      }

      & .options_list {
        @include m.setStyleForFlexBox(column, flex-start, flex-start);

        & .option {
          width: 100%;

          & a:hover {
            background-color: var(--boschGray90) !important;
          }

          & i,
          & span {
            color: var(--boschBlack) !important;
          }
        }
      }
    }
  }
}
