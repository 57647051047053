@use "../../styles/responsive.module" as r;

.footer {
  background-color: var(--boschWhite) !important;

  :global(.o-footer__copyright) {
    color: var(--boschBlack) !important;
  }

  :global(.a-link) {
    & a {
      color: var(--boschBlack) !important;
    }
  }

  :global(.e-container) {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding: 1rem !important;

    :global(.o-footer__bottom) {
      max-width: 100% !important;
    }
  }
}

@include r.tablet_screen_and_up {
  .footer {
    :global(.e-container) {
      padding: 0 !important;

      :global(.o-footer__bottom) {
        padding: var(--card-spacing-sm) !important;
      }
    }
  }
}

@include r.laptop_s_screen_and_up {
  .footer {
    :global(.e-container) {
      :global(.o-footer__bottom) {
        padding: var(--card-responsive-spacing) var(--card-spacing-lg) !important;
      }
    }
  }
}
