/*Start addelearningtocartsuccess_modal_wrapper*/
.addelearningtocartsuccess_modal_wrapper{
    display: flex;
    justify-content: center !important;
}

.addelearningtocartsuccess_modal_wrapper.warning > .modal{
    border-top: 6px solid rgb(255, 207, 0);
}

.addelearningtocartsuccess_modal_wrapper.error > .modal{
    border-top: 6px solid rgb(237, 0, 7);
}

.addelearningtocartsuccess_modal_wrapper.success > .modal{
    border-top: 6px solid #00884a;
}

.addelearningtocartsuccess_modal_wrapper > .modal > .header{
    border: 0 !important;
    padding-bottom: 0 !important;
    align-items: flex-start;
    display: flex;
    font-size: var(--fs-70) !important;
}

.addelearningtocartsuccess_modal_wrapper > .modal > .header > i{
    font-size: var(--fs-60) !important;
    margin-top: 1px;
    font-weight: bold;
}

.addelearningtocartsuccess_modal_wrapper > .modal > .content > .ui.grid{
    margin: 0;
}

.addelearningtocartsuccess_modal_wrapper > .modal > .actions{
    border: 0 !important;
    padding-top: 0 !important;
    display: flex;
    gap: var(--card-responsive-spacing);
    justify-content: flex-end;
}

.addelearningtocartsuccess_modal_wrapper > .modal > .actions > button{
    min-width: 125px;
    font-weight: normal;
    margin: 0 !important;
}
/*End addelearningtocartsuccess_modal_wrapper*/