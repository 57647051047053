.button {
  line-height: 1.5 !important;
  padding: 0.75rem 1rem !important;
  margin: 0 !important;
  max-height: 3rem !important;
  background-color: var(--boschGray90) !important;
  color: var(--boschBlack) !important;
}
/* .primary {
  background-color: var(--boschBlue50) !important;
  color: var(--boschWhite) !important;
}
.primary:hover {
  background-color: var(--boschBlue40) !important;
}
.primary:active {
  background-color: var(--boschBlue30) !important;
  color: var(--boschWhite) !important;
}
.primary.disabled {
  background-color: var(--boschGray80) !important;
  color: var(--boschGray60) !important;
} */

.ui.button.disabled {
  background-color: var(--boschGray80) !important;
  color: var(--boschGray60) !important;
  opacity: 1 !important;
}

.button.small {
  line-height: 0.75 !important;
}

.primary {
  background-color: var(--major-accent__enabled__fill__default) !important;
  color: var(--major-accent__enabled__front__default) !important;
}
.primary:hover {
  background-color: var(--major-accent__enabled__fill__hovered) !important;
}
.primary:active {
  background-color: var(--major-accent__enabled__fill__pressed) !important;
}
.primary:disabled {
  background-color: var(--major-accent__disabled__fill__default) !important;
  color: var(--major-accent__disabled__front__default) !important;
}

.secondary {
  background-color: var(--minor-accent__enabled__fill__default) !important;
  border: 1px solid var(--minor-accent__enabled__front__default) !important;
  color: var(--minor-accent__enabled__front__default) !important;
}
.secondary:hover {
  background-color: var(--minor-accent__enabled__fill__hovered) !important;
  border-color: var(--minor-accent__enabled__front__hovered) !important;
  color: var(--minor-accent__enabled__front__hovered) !important;
}
.secondary:active {
  background-color: var(--minor-accent__enabled__fill__pressed) !important;
  border-color: var(--minor-accent__enabled__front__pressed)!important;
  color: var(--minor-accent__enabled__front__pressed)!important;
}
.secondary:disabled {
  background-color: var(--minor-accent__disabled__fill__default)!important;
  border-color: var(--minor-accent__disabled__front__default)!important;
  color: var(--minor-accent__disabled__front__default)!important;
}

/* .secondary {
  background-color: var(--boschWhite) !important;
  border: 1px solid var(--boschBlue40) !important;
  color: var(--boschBlue40) !important;
}
.secondary:hover {
  background-color: var(--boschBlue90) !important;
  color: var(--boschBlue40) !important;
}
.secondary:active {
  background-color: var(--boschBlue80) !important;
  color: var(--boschBlue30) !important;
}
.secondary.disabled {
  background-color: var(--boschGray75) !important;
  color: var(--boschGray55) !important;
} */

.button.signal_error {
  background-color: var(--boschRed50) !important;
  color: var(--boschWhite) !important;
}
.button.signal_error:hover {
  background-color: var(--boschRed40) !important;
  color: var(--boschWhite) !important;
}

.button.signal_error:active {
  background-color: var(--boschRed30) !important;
}
.button.signal_error:disabled {
  background-color: var(--major-accent__disabled__fill__default) !important;
  color: var(--major-accent__disabled__front__default) !important;
}

.button.signal_success {
  background-color: var(--boschGreen50) !important;
  color: var(--boschWhite) !important;
}
.button.signal_success:hover {
  background-color: var(--boschGreen40) !important;
  color: var(--boschWhite) !important;
}

.button.signal_success:active {
  background-color: var(--boschGreen30) !important;
}
.button.signal_success:disabled {
  background-color: var(--major-accent__disabled__fill__default) !important;
  color: var(--major-accent__disabled__front__default) !important;
}
