#active_level1_menu{
    color: var(--boschBlue50) !important;
}

.logout_modal{
    border-top: 6px solid var(--boschYellow85) !important;
}

/* .support{
    padding-bottom: 1.5rem;
} */

.menu_btn{
    box-shadow: none !important;    
    display: flex !important;
    margin-right: 0 !important;
    align-items: center;
    padding: 0 !important;
    padding-left: 0 !important;
    font-size: var(--fs-60) !important;
    margin-top: -5px !important;
}

.header_level1{
    position: absolute;
    top: 100%;
    z-index: 1000;
    background: var(--boschWhite);
    width: 100vw;
    margin: 0 !important;
    padding-top: var(--card-spacing-sm) !important;
    padding-bottom: 2rem !important;
    box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.25);
    margin-left: calc(0px - (100vw - var(--CDM3-max-width))/2) !important;
    align-items: flex-start !important;
}


.header_level1 > div{
    /* width: 33% !important; */
    width: calc(var(--CDM3-max-width)/3) !important;
}

.header_level1 *{
    font-size: 1rem !important;
}

.header_level1_col{
    /* width: fit-content !important; */
    border-right: 1px solid var(--boschGray70);
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    margin-left: calc((100vw - var(--CDM3-max-width))/2) !important;
}

.header_level1_col button span:hover,
.header_level2_col button span:hover{
    text-decoration: underline;
    color: var(--boschBlue50);
}


.header_level1_col > div > button{
    box-shadow: none !important;
    padding-left: 0 !important;
    color: var(--boschBlack) !important;
}

.header_level2_col{
    /* width: fit-content !important; */
    width: calc(((100vw - var(--CDM3-max-width))/3)) !important;
    border-right: 1px solid var(--boschGray70);
    padding-top: 0 !important;
}



.header_level2_col > div > button{
    box-shadow: none !important;
}



.arrow_row{
    display: flex !important;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding-right: 0 !important;
    width: 100% !important;
}

.arrow_row > i{
    font-size: var(--card-spacing) !important;
}

.arrow_link{
    display: flex !important;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: start;
    gap: 5px;
}

.first_row{
    padding-top: 1.5rem !important;
}

.support{
    padding-bottom: 1.5rem !important;
}

.last_row{
    border-bottom: 1px solid var(--boschGray70) !important;
}

.locations{
    display: flex !important;
    flex-direction: row;
    align-items: center;
}

.locations i{
    display: flex;
    align-items: center;
}

.locations i svg{
    fill: var(--boschBlue50);
}

.header_level1_col > div:last-child > .locations{
    color:var(--boschBlue50) !important;
    font-weight: bold !important;
}
.header_level1_col > div:last-child > .locations:hover{
    color:var(--boschBlue40) !important;
}
.header_level1_col > div:last-child > .locations:hover i svg{
    fill: var(--boschBlue40) !important;
}

#header_level2_col_title{
    font-weight: bold !important;
    margin-bottom: 1rem;
    color: var(--boschBlack) !important;
}

.blank_col{
    width: -moz-fit-content;
    width: fit-content;
}

.right_header_item{
    font-weight: bold !important;
    font-size: var(--fs-normal) !important;
    padding-right: 0 !important;
}

@media (max-width: 1300px){
    .header_level1{
        margin-left: 0!important;
    }
    .header_level1_col{
        margin-left: 0!important;
    }
    .header_level1 > div{
        width: 33% !important;
    }
}