$tablet: 768px;
$laptop_s: 1280px;
$desktop_m: 1920px; // Full HD resolution
$bosch_ads_625x: 2000px; // For Bosch 625x Android tablet which has resolution 2000 * 1200 (wuxga+)
$desktop_l: 2560px; // 2K resolution
$desktop_xl: 3200px; // 3K resolution
$desktop_2xl: 3840px; // 4K resolution

//COMMON CUSTOM SCREEN
$custom_screen_1550: 1550px;

@mixin tablet_screen_and_up {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin laptop_s_screen_and_up {
  @media (min-width: $laptop_s) {
    @content;
  }
}

@mixin desktop_m_screen_and_up {
  @media (min-width: $desktop_m) {
    @content;
  }
}

@mixin bosch_ads_625x_screen_and_up {
  @media (min-width: $bosch_ads_625x) and (hover: none) {
    @content;
  }
}

@mixin desktop_l_screen_and_up {
  @media (min-width: $desktop_l) {
    @content;
  }
}

@mixin desktop_xl_screen_and_up {
  @media (min-width: $desktop_xl) {
    @content;
  }
}

@mixin desktop_2xl_screen_and_up {
  @media (min-width: $desktop_xxl) {
    @content;
  }
}

// For custom screen
@mixin custom_screen_and_up($screen_width: $custom_screen_1550) {
  @media (min-width: $screen_width) {
    @content;
  }
}

// For touch screen
@mixin touch_screen_style {
  @media (hover: none) {
    @content;
  }
}
