.supergraphic {
  position: relative;
  display: flex;

  width: 100%;
  height: 0.375rem;
  z-index: 0;
}

/* header box container */
.header-large-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 6rem;
}

.sticky-menu-header {
  visibility: hidden;

  opacity: 0;
}

.sticky-header-shown .sticky-menu-header .header-level-1 {
  position: fixed;

  width: 100vw !important;
  padding: 0 1rem;
  top: 6rem;
  left: 0;
}

.landing-page-title {
  margin-top: 0 !important;
  font-weight: bold !important;
  font-size: larger;
  text-align: right;
}

/* header buttons (contact - search - menu) */
.ui.grid.header-buttons {
  height: 3rem;
  margin: 0rem;
}
.ui.grid.header-buttons > .row {
  padding: 0rem;
}
.ui.grid.header-buttons button.ui.button {
  font-size: 16px;
  line-height: 24px;
  color: #000000 !important;
  height: 100%;
}
.ui.grid.header-buttons .ui.basic.button:hover {
  color: rgba(0, 0, 0, 1) !important;
}
button.ui.button.header-icon-button:active {
  background: transparent !important;
}
button.ui.button.header-icon-button#menu-btn {
  margin-right: -1rem;
}
button.ui.button.header-icon-button {
  box-shadow: none !important;
  margin: 0rem;
  align-items: center;
  padding: 0 !important;
  display: flex;
}

button.ui.button.header-icon-button i.icon {
  font-size: 24px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  box-sizing: initial;
  padding: 0.75rem 0.75rem 0.75rem 0.875rem;
  margin: 0 !important;
}

button.ui.button.header-icon-button > span {
  display: none;
}

@media only screen and (min-width: 768px) {
  button.ui.button.header-icon-button > span {
    display: block;
  }

  button.ui.button.header-icon-button i.icon {
    padding-right: 0.5rem;
  }
  button.ui.button.header-icon-button:last-child i.icon {
    padding-right: 0.5rem;
  }
}

button.ui.button.header-icon-button span {
  padding: 0 1rem 0 0;
}

/* .ui.grid.header-buttons button.ui.labeled.icon.button>.icon {
  background-color: transparent;
} */
/* breadcumb box */
.breadcumb-box-account-cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.breadcumb-box-account-cart .ui.grid {
  margin: 0;
}

.breadcumb-box-account-cart button.ui.button {
  box-shadow: none;
  font-size: 12px;
  line-height: 18px;
  color: #000 !important;
}
/* .breadcumb-box-account-cart .ui.grid button.ui.button {
  margin: 0rem;
  align-items: center;
  padding: 0 0.5rem 0 !important;
} */

/* .breadcumb-box-account-cart button.ui.labeled.icon.button>.icon {
  background-color: transparent;
} */

div.header-menu-popup-content {
  /* margin-top: 0;
  box-shadow: none;
  border: none;
  padding-right: 75rem; */
  background: #fff;
  width: 100%;
  margin-top: -1.5%;
}
div.ui.popup.header-menu-popup::before {
  display: none;
}

/*breadcrumb*/
.breadcumb-box-account-cart .ui.breadcrumb a.section {
  font-size: 12px;
  color: #bfc0c2;
}
.breadcumb-box-account-cart .ui.breadcrumb a.section:last-child {
  color: #000;
}
.breadcumb-box-account-cart .ui.breadcrumb i.icon {
  color: #000;
  vertical-align: middle;
  margin: 0 0.5rem;
}

.breadcumb-box-account-cart > .ui.grid > .row {
  padding: 0 !important;
}

/*menu popup*/
div.header-search-popup-content {
  width: 100%;
  padding-left: var(--card-spacing-sm);
  padding-right: var(--card-spacing-sm);
}
div.header-search-popup-content > div:first-child {
  display: none;
}
div.header-search-popup-content div.ui.input {
  width: 100%;
}

div.header-search-popup-content div.ui.input button {
  background: #e0e2e5;
  border-radius: 0 !important;
  border-bottom: 1px solid black;
}

div.header-search-popup-content div.ui.input input {
  border: 0;
  background: #e0e2e5;
  border-radius: 0;
  border-bottom: 1px solid black;
}
.login-popup-btn {
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
}
.login-popup-btn:nth-child(1) {
  margin-bottom: 0.5rem;
}

.menu-popup-segment {
  box-shadow: 0 -16px 0 #fff, 0 0 16px rgb(0 0 0 / 25%) !important;
  margin-top: 1rem !important;
}

button.ui.button.header-icon-button span,
button.ui.button.header-icon-button.header-cart {
  /* padding: 0 1rem 0 0 !important; */
  padding: 0 !important;
}

.grid-responsive-width .flag-size {
  width: 20px !important;
  height: 20px !important;
}

.logout-modal-wrapper > .modal {
  border-top: 6px solid rgb(255, 207, 0);
}
.logout-modal-wrapper > .modal > .content {
  display: flex;
  align-items: center;
}
.logout-modal-wrapper > .modal > .content > i {
  font-size: 1.5em;
}
.logout-modal-wrapper > .modal > .content > span {
  padding-top: 10px;
}

.change-regions-modal-wrapper > .modal {
  border-top: 6px solid rgb(255, 207, 0);
}
.change-regions-modal-wrapper > .modal > .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.change-regions-modal-wrapper > .modal > .content > i {
  font-size: 1.5em;
}
.change-regions-modal-wrapper > .modal > .content > span {
  padding-top: 10px;
}

.training_course_detail_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* 
.training_course_detail_header>.ui.button{
  margin: 0 !important;
  background-color: var(--boschBlue50) !important;
  display:flex !important; 
  align-items:center !important; 
  color: var(--boschWhite) !important;
  padding: var(--card-spacing-vertical) !important;
  justify-content: center;
  font-weight: normal;
}

.training_course_detail_header>.ui.button >i{
  cursor: pointer !important ;
  color: var(--boschWhite) !important ;
  display:flex !important;
  align-items:center !important;
  font-size:2rem !important;
  margin:0 !important
} */

.price_container {
  display: flex;
  flex-direction: column;
  /* margin-right: 1rem !important; */
}

.price_container > .price-currency {
  font-weight: bold !important;
}

.vat-text {
  font-size: 10px;
}
.header-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  max-width: 1300px !important;
  margin: 0 auto;
  padding: var(--card-spacing-sm) !important;
}

.header-text-container > i {
  font-size: var(--fs-20) !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 !important;
  margin: 0 !important;
}

.maintenance_message {
  background: var(--boschBlue85) !important;
  color: var(--boschBlack) !important;
  margin: 0 auto !important;
  border-radius: unset !important;
  min-height: 2rem;
  box-shadow: none !important;
  padding: var(--card-responsive-spacing) var(--card-spacing) !important;
}
.maintenance_message .icon {
  font-size: var(--fs-60) !important;
}

.maintenance_detail > .content {
  display: flex !important;
  align-items: flex-start;
  padding-right: var(--card-spacing);
}
.maintenance_detail > .content > i:first-child {
  margin-right: var(--card-responsive-spacing);
}
.maintenance_detail > .content p {
  margin: 0;
  margin-top: 2px;
  opacity: 1;
  font-size: var(--fs-80);
}
.maintenance_detail > .content > i:last-child {
  margin: 0;
  position: absolute;
  right: var(--card-spacing);
  margin-right: -5px;
}
.maintenance_detail > .content > i:last-child:hover {
  cursor: pointer;
}
.maintenance_block {
  background: var(--boschBlue85) !important;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .header-large-box {
    padding: 0rem;
    height: 3rem;
  }

  .sticky-header-shown .sticky-menu-header .header-level-1 {
    top: 3rem;
  }

  .landing-page-title {
    font-size: 0.6rem !important;
    margin-top: 0 !important;
  }
  .ui.grid.header-buttons > .row > .column button.ui.button {
    padding: 1.3rem !important;
  }
  /* breadcumb box */
  /* .breadcumb-box-account-cart {
    padding: 3rem 0;
  } */
  .menu-popup-segment {
    top: 0.375rem !important;
  }
  .row.header-icon-mobile > button.ui.button.header-icon-button i.icon {
    padding-right: 0.1rem !important;
  }
  button.ui.button.header-icon-button span,
  button.ui.button.header-icon-button.header-cart {
    padding: 0 !important;
  }
  .maintenance_message {
    padding: var(--card-responsive-spacing) var(--card-spacing)
      var(--card-responsive-spacing) var(--card-spacing-sm) !important;
  }
  .maintenance_detail > .content {
    padding-right: 0 !important;
  }
  .maintenance_detail > .content > i:first-child {
    display: none;
  }
  .maintenance_detail > .content > i:last-child {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: var(--fs-77) !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* .row.header-icon-mobile > button.ui.button.header-icon-button i.icon {
    padding-right: 1rem !important;
  } */
  button.ui.button.header-icon-button > span {
    display: none;
  }
  button.ui.button.header-icon-button span,
  button.ui.button.header-icon-button.header-cart {
    padding: 0 !important;
  }
}

@media (min-width: 1500px) {
  .home-img {
    width: 133%;
    max-width: 133%;
    left: -33%;
    height: fit-content;
  }
}

@media (max-width: 800px) {
  .home-img {
    width: 100%;
    max-width: 133%;
    left: -10%;
    height: fit-content;
  }
}

@media only screen and (min-width: 768px) and (max-height: 700px) {
  .menu-popup-segment {
    top: 5rem !important;
  }
  button.ui.button.header-icon-button:last-child i.icon {
    padding-right: 0rem;
  }
}
@media only screen and (min-width: 768px) and (min-height: 701px) {
  /* .menu-popup-segment {
    top: 0.375rem !important;
  } */
  button.ui.button.header-icon-button:last-child i.icon {
    padding-right: 0rem;
  }
}

@media (max-width: 991px) {
  .menu-popup-segment {
    top: 0.375rem !important;
  }
  .landing-page-title {
    margin-top: 0 !important;
  }
}

.ui.grid.header-buttons > .row.header-icon-mobile {
  padding: 0 !important;
  height: 100%;
}

button.ui.button.header-icon-button.header-language-icon span,
button.ui.button.header-icon-button.header-cart.language-text {
  position: absolute;
  /* padding-left: 21px !important;
  padding-bottom: 8px !important; */
  font-size: 10px;
  display: block;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

button.ui.button.header-icon-button i.icon.language-icon {
  font-size: 24px;
  padding: 0 !important;
}

.ui.bottom.center.popup::before {
  border-radius: 5px !important;
}

.ui.vertical.menu.pick-language-box {
  border-radius: 5px !important;
}

.ui.menu .item.language-options {
  position: initial !important;
}

.ui.bottom.center.popup {
  border-radius: 5px !important;
}

#lang-test {
  border-radius: 5px !important;
}

.available-languages-header {
  color: #999 !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
  font-size: var(--f-font-11-size) !important;
}

.anonymous-user {
  margin: 0 auto 0 auto !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: var(--CDM3-max-width);
  width: calc(100% - 2rem);
  min-height: 80px;
  background-color: var(--boschGray95);
}

.login-button {
  background: var(--boschBlue50) !important;
  color: var(--boschWhite) !important;
  padding: var(--card-spacing-vertical) !important;
  margin-right: 0 !important;
  font-weight: normal !important;
}

.anonymous-header {
  font-size: var(--fs-60);
  font-weight: 600;
  line-height: 1.25;
  margin-top: var(--card-responsive-spacing);
}

.anonymous-header-no-button {
  font-size: var(--fs-60);
  font-weight: 600;
  line-height: 1.25;
}

.header-text-container {
  display: flex;
  align-items: flex-start;
}

.header_logo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.checkout_cart {
  padding-left: 1rem;
  border-left: 1px solid var(--boschBlack);
}

@keyframes reveal {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

@media (max-width: 575px) {
  .anonymous-header,
  .anonymous-header-no-button {
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem;
    margin: 0.5rem;
    line-height: 1;
  }
  .header-search-popup-content {
    margin-top: -6px;
    padding: 0 !important;
  }
}

.header-language-icon {
  position: relative !important;
  margin-left: 0.5rem !important;
}

@media (max-width: 1199px) {
  .right-header {
    display: none !important;
  }
}
.ui-header-wrapper .menu-ui {
  gap: var(--fs-20);
  align-items: center !important;
}

.ui-header-wrapper {
  max-width: var(--CDM3-max-width);
}
.ui-header-wrapper .menu-ui > .menu-items {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.ui-header-wrapper .menu-ui::after {
  display: none !important;
}
.ui-header-wrapper .menu-ui > .menu-items.right-header {
  padding-right: var(--card-spacing) !important;
}

@media (min-width: 768px) {
  .header-large-box {
    height: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .search-field-container {
    padding: 1rem 0 0;
    animation: reveal 250ms forwards;
  }
}

@media (max-width: 991px) {
  .ui-header-wrapper {
    display: none;
  }
}

@media (min-width: 992px) {
  .open-menu-button-mobile {
    display: none !important;
  }
}

.menu-mobile {
  overflow: hidden;
}

.menu-header-items > .column > .row > .header-icon-button > .icon {
  padding-left: 0 !important;
}

@media (max-width: 991px) {
  .icon-desktop {
    display: none !important;
  }
  .anonymous-user {
    width: calc(100% - 2rem);
  }

  .anonymous-user.homepage {
    width: calc(100% - 4rem);
  }
}

@media (max-width: 767px) {
  .anonymous-header,
  .anonymous-header-no-button {
    padding: 0 !important;
    margin: 0 !important;
    padding-right: 1rem !important;
    font-size: var(--fs-70);
    width: 100% !important;
  }
  .header-text-container {
    width: 100% !important;
    gap: var(--card-responsive-spacing);
  }
  .header-bar {
    padding: var(--fs-normal) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    width: 100% !important;
    gap: 1rem !important;
  }
  .header-bar > button {
    width: 100% !important;
  }
  .header-text-container > i {
    font-size: var(--fs-50) !important;
    display: none !important;
  }
}

@media (max-width: 357px) {
  .header-bar {
    padding: 0.5rem !important;
  }
  .login-button {
    padding: 0.5rem !important;
  }
  .anonymous-header,
  .anonymous-header-no-button {
    padding: 0 !important;
    margin: 0 !important;
  }
}

@media (min-width: 1024px) {
  .sticky-super-graphic-shown {
    position: fixed;

    top: 0;
    left: 0;
    width: 100%;
    z-index: 500;
  }

  .sticky-header-shown {
    position: fixed;
    background-color: var(--boschWhite);

    top: 0.375rem;
    left: 0;
    z-index: 500;
  }

  .sticky-header-shown .sticky-menu-header {
    visibility: visible;

    opacity: 1;
  }
}

@media (min-width: 1280px) {
  .sticky-header-shown {
    transform: translateX(-50%);

    left: 50%;
  }

  .sticky-header-shown .sticky-menu-header .header-level-1 {
    padding: 0;
  }
}

@media (max-width: 991px) {
  .icon_desktop {
    display: none !important;
  }
}
