@use "../../../styles/dimension.module" as d;
@use "../../../styles/responsive.module" as r;

.container {
  :global(.a-box--modal) {
    z-index: 9999;
    overflow-y: auto;
    flex-direction: row;
    align-items: safe center;

    & > :global(.a-box) {
      background-color: var(--boschWhite);
      max-width: 96vw;
      min-width: 96vw;

      & :global(.m-dialog__header) {
        padding: 0 1rem;

        & > i {
          color: var(--boschBlack);

          margin-right: 0.5rem;
        }

        & :global(.m-dialog__title) {
          color: var(--boschBlack);
        }

        & :global(.a-button--integrated) {
          margin-right: -1rem;
        }
      }

      & :global(.m-dialog__content) {
        padding: 1.5rem 1rem 0;

        & :global(.a-button--primary) {
          background-color: var(--boschBlue50);
          color: var(--boschWhite);

          &:hover {
            background: var(--boschBlue40);
          }

          &:active {
            background: var(--boschBlue30) !important;
          }
        }

        & :global(.a-button--secondary) {
          background: var(--boschWhite);
          color: var(--boschBlue50);

          border: 0.0625rem solid var(--boschBlue50) !important;

          &:hover {
            background: var(--boschBlue90);
            color: var(--boschBlue40);
            border-color: var(--boschBlue40);
          }

          &:active {
            background: var(--boschBlue80);
            color: var(--boschBlue30);
            border-color: var(--boschBlue30);
          }
        }
      }
    }
  }
}

@include r.tablet_screen_and_up {
  .container,
  .container.m {
    :global(.a-box--modal) {
      & > :global(.a-box) {
        background-color: var(--boschWhite);
        max-width: d.$dialog_max_width_m;
        min-width: d.$dialog_min_width_m;

        & :global(.m-dialog__header) {
          padding: 0 2rem;

          & :global(.a-button--integrated) {
            margin-right: -2rem;
          }
        }

        & :global(.m-dialog__content) {
          padding: 1.5rem 2rem 0;
        }
      }
    }
  }

  .container.l {
    :global(.a-box--modal) {
      & > :global(.a-box) {
        max-width: d.$dialog_max_width_l;
        min-width: d.$dialog_min_width_l;
      }
    }
  }
}
