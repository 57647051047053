.training-program-assign-modal .modal > .content {
  padding-top: 0;
  padding-bottom: 0;
}
.training-program-assign-modal .modal .ui.grid > .row > .column {
  padding-left: 0;
  padding-right: 0;
}
.training-program-assign-modal .modal .header .ui.grid > .row {
  padding: 0 !important;
}
.column h5.course-name {
  font-size: var(--fs-70) !important;
  font-weight: bold !important;
}
.training-program-assign-modal
  .traning-program-student-choice.ui.search.dropdown {
  width: 100%;
  background-color: var(--boschSilver) !important;
  border: 0 !important;
  border-radius: 0 !important;
  margin-top: 0.5rem !important;
}

.training-program-assign-modal
  .traning-program-student-choice.ui.search.dropdown
  > .text {
  color: var(--boschBlack) !important;
  width: 100% !important;
}

.traning-program-student-choice.ui.search.dropdown > i.icon {
  font-family: Bosch-Icon, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: var(--fs-60) !important;
  position: absolute;
  right: 0.5rem;
  margin: 0 !important;
  cursor: pointer !important;
  z-index: 10;
}
.training-program-assign-modal .button-assign.ui.button {
  background: var(--boschBlue50);
  color: var(--boschWhite);
  margin: 0 !important;
  min-width: 7.5rem !important;
  padding: var(--card-spacing-xs-vertical);
}

.training-program-assign-modal .button-assign.ui.grid > .row > .column {
  display: flex;
  flex-direction: row-reverse;
}

.training-program-assign-modal .course-info-training-program {
  border: none !important;
}

.training-program-assign-modal > .modal.assign-modal {
  border-top: 0.5rem solid var(--boschGreen50) !important;
  border-radius: 0 !important;
}

.assign-modal-add-user-icon > i {
  font-size: 3rem !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  width: fit-content !important;
}

.assign-modal-header {
  display: flex !important;
}
.assign-modal-header.ui.grid > .column.assign-modal-title {
  display: flex !important;
  align-items: center !important;
}

.assign-modal-close-icon > i {
  font-size: 2rem !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  width: fit-content !important;
  text-align: left !important;
}

.assign-modal-header.ui.grid {
  margin: 0 !important;
  /* padding: 0 !important; */
  width: 100% !important;
  padding: 1.5rem !important;
}

.assign-modal-header.ui.grid > .column {
  padding: 0 !important;
  border: 0 !important;
}
.ui.modal.assign-modal > .header {
  padding: 0 !important;
  border: 0 !important;
}
.button-area {
  text-align: end !important;
}

.button-area > .column {
  display: flex !important;
  justify-content: end;
  gap: 1rem !important;
}

.training-program-assign-modal .ui.button.button-cancel {
  background-color: var(--boschWhite) !important;
  border: 1px solid var(--boschBlue50) !important;
  color: var(--boschBlue50) !important;
  min-width: 7.5rem !important;
  margin: 0 !important;
  padding: var(--card-spacing-xs-vertical);
}

.assign-modal-mini {
  padding: 0 !important;
}
