$base_screen_max_width: 1300px;
$bosch_ads_625x_screen_max_width: 95vw;

$ads_us_search_by_plate_padding_left: 5.5rem;
$ads_us_search_by_plate_option_height: 3rem;

$dialog_max_width_l: 70rem;
$dialog_max_width_m: 40rem;
$dialog_min_width_l: 50rem;
$dialog_min_width_m: 30rem;
