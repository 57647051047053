.redirect-country-modal .modal-content-custom {
  text-align: center !important;
}

.redirect-country-modal .modal-content-custom > p {
  width: 100%;
  margin: 0 auto;
}

.redirect-country-modal .button.ui.button {
  border-radius: 0;
}

.redirect-country-modal .button-custom {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}

.redirect-country-modal .button-custom > button {
  margin-right: 0px !important;
}
