.menu_options > div > a > i, .menu_options > div > div > i{
    padding: 0 !important;
    margin-right: var(--card-spacing-xs) !important;
    display: flex !important    ;
    align-items: center;
}

.menu_options > div > a, .menu_options > div > div{
    display: flex !important;
    align-items: center !important;
}

.menu_options > div:first-child{
    display: none !important;
}

.menu_options > div:last-child{
    z-index: 9999 !important;
    top: 150% !important;
    min-width: 200px !important;
    left: -45px !important;
    border-radius: 0 !important;
}

.menu_options > div:last-child::before{
    border-radius: 4px !important;
    content: '';
    width: 20px;
    height: 20px;
    background: var(--boschWhite);
    transform: rotate(45deg);
    z-index: 2;
    position: absolute;
    left: 46px;
    right: auto;
    top: -8px;
    bottom: auto;
    box-shadow: -1px -1px 0 0 rgba(34,36,38,.15);
}

.menu_options > div:last-child a{
    background: var(--boschWhite) !important;
}

.menu_options > div:last-child a:hover{
    background: var(--boschGray95) !important;
}

#menu_options_id.menu_options > div > a > i, 
#menu_options_id.menu_options > div > a > svg, 
#menu_options_id.menu_options > div > div > i{
    margin-right: var(--card-spacing-xs) !important;
}

#menu_options_id.menu_options > div > a > i,#menu_options_id.menu_options > div > div > i{
    font-size: var(--card-spacing);
    width: var(--card-spacing);
}

.logout_modal{
    border-top: 6px solid var(--boschYellow85) !important;
}

#icon_mobile{
    padding: 12px 8px 12px 14px !important
}

#icon_mobile .menu_options{
    font-size: var(--card-spacing);
}

#icon_mobile svg{
    width: var(--card-spacing);
}

#icon_mobile > div > div:last-child > div:first-child{
    display: none !important;
}

#icon_desktop{
    padding: 12px 8px 12px 14px !important;
    margin-right: 0 !important;
    box-shadow: none !important;
}

#icon_desktop i{
    padding: 0 !important;
    margin: 0 !important;
    width: fit-content !important;
    height: auto !important;
    display: flex !important;
    align-items: center !important;
}

#icon_desktop .menu_options{
    font-size: var(--card-spacing);
}

#icon_desktop svg{
    width: var(--card-spacing);
}

#icon_desktop > div > div:last-child svg{
    height: var(--card-spacing) !important;
    width: var(--card-spacing) !important;
    /* width: 18.88px !important; */
    margin-right: var(--card-spacing-xs) !important;
}

#icon_mobile > div > div:last-child svg{
    height: var(--card-spacing) !important;
    width: var(--boschYellow85) !important;
    margin-right: var(--card-spacing-xs) !important;
}



@media (max-width:991px){
    #icon_desktop{
        display: none;
    }
}