.waitlist-modal.modal {
  border-top: 6px solid #00884a;
}

.waitlist-modal > .content {
  padding: 1.25rem !important;
}
.waitlist-modal > .content > .ui.grid > .row {
  padding-bottom: 0 !important;
}
.waitlist-modal > .content > .ui.grid > .row > .column {
  padding: 0;
}
.waitlist-popup .ui.modal > .close {
  top: 0px;
  right: 0px;
  color: #000;
}

.waitlist-popup .course-name-area .course-name {
  font-weight: 700 !important;
  margin-bottom: 0px;
}

.grid.ui > .row.course-name-area {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.waitlist-popup .signup-without-select-date-area {
  display: flex !important;
  justify-content: space-between;
  margin-bottom: 0.5rem !important;
}

.waitlist-popup .signup-without-select-date-area .signup-without-select-date {
  font-weight: 700 !important;
}

.waitlist-popup .action-col {
  text-align: right !important;
}

.waitlist-popup .ui.grid > .row.button-area {
  text-align: right !important;
  margin-top: 1rem !important;
  padding: 0 !important;
}

.waitlist-popup .dropdown-row {
  flex-direction: column;
  display: flex !important;
  margin-top: 0.5rem !important;
}

/* .dropdown-row > .dropdown{
  width: 50% !important;
} */

.waitlist-popup .bottom-area {
  margin-top: 1rem !important;
}

.waitlist-popup .close-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 0px;
  margin-bottom: 0.2rem;
  margin-left: 0px;
  margin-right: 0px;
}

.remove-waitlistitem-popup .buttons > .yes-confirm-button {
  width: 30% !important;
  margin-right: 2rem !important;
}

.remove-waitlistitem-popup .buttons > .no-confirm-button {
  width: 30% !important;
  border-radius: 0px !important;
}

.waitlist-popup-center {
  justify-content: center !important;
}

.waiting-popup-dropdown.dropdown.ui {
  width: 100%;
  background-color: var(--boschSilver) !important;
  border: 0 !important;
  border-radius: 0 !important;
  margin-top: 0.5rem !important;
  line-height: initial !important;
}

.waiting-popup-dropdown.ui.dropdown > i.icon {
  font-family: Bosch-Icon, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: var(--fs-60) !important;
  position: absolute;
  right: 0.5rem;
  margin: 0 !important;
  margin-top: -0.25rem !important;
  cursor: pointer !important;
  z-index: 10;
}

.waiting-popup-dropdown.ui.dropdown > i::after {
  content: none !important;
  padding: 0;
}

.waiting-popup-dropdown.ui.dropdown > i:before {
  content: none !important;
}

@media (max-width: 767px) {
  /* .remove-waitlistitem-popup .buttons{
    justify-content: space-between !important;
  } */
  .remove-waitlistitem-popup .buttons > .yes-confirm-button {
    width: auto !important;
  }

  .remove-waitlistitem-popup .buttons > .no-confirm-button {
    width: auto !important;
    border-radius: 0px !important;
  }

  .waitlist-popup > .waitlist-modal {
    padding-bottom: 1rem !important;
  }
  .dropdown-row > .dropdown {
    width: 100% !important;
  }
}
