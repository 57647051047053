@use "../../../styles/mixins.module" as m;

.wrapper {
  @include m.setStyleForFlexBox(
    column,
    flex-start,
    flex-start,
    var(--card-responsive-spacing)
  );

  & .message {
    color: var(--boschBlack);
  }
}
