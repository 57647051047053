:root {
  --search-field-button-width: 40px;
  --search-field-input-z-index: 10;
  --search-field-input-padding-left: 1rem;
  --search-field-button-z-index: 30;
  --search-field-input-height: 3rem;
  --search-field-input-border-bottom-weight: 1px;
  --search-field-input-default-mode-bgr-clr: var(--boschGray90);
  --search-field-input-default-mode-hovered-bgr-clr: var(--boschGray95);
  --search-field-input-default-mode-focused-bgr-clr: var(--boschBlue90);
  --search-field-input-footer-mode-bgr-clr: var(--boschGray30);
  --search-field-input-footer-mode-hovered-bgr-clr: var(--boschGray40);
  --search-field-input-footer-mode-focused-bgr-clr: var(--boschBlue30);
  --search-field-button-default-mode-hovered-bgr-clr: var(--boschGray80);
  --search-field-button-footer-mode-hovered-bgr-clr: var(--boschGray40);
  --search-field-button-icon-size: var(--fs-60);
}

.input-container,
.footer-input-container {
  display: flex;
  flex-direction: row;
  position: relative;

  width: 100%;
  margin: 0px;
}

.input-container .ui.input,
.footer-input-container .ui.input {
  z-index: var(--search-field-input-z-index);

  width: 100%;
}

.input-container .ui.input.search-input input,
.footer-input-container .ui.input.search-input input {
  padding-left: var(--search-field-input-padding-left);
  padding-right: calc(var(--search-field-button-width) * 2);
  height: var(--search-field-input-height) !important;

  height: 100%;
  border: 0 !important;
  border-radius: 0 !important;
}

.input-container .ui.input.search-input > input {
  background-color: var(--search-field-input-default-mode-bgr-clr) !important;
  border-bottom: var(--search-field-input-border-bottom-weight) solid
    transparent !important;
}

.footer-input-container .ui.input.search-input > input {
  background-color: var(--search-field-input-footer-mode-bgr-clr) !important;
  border-bottom: var(--search-field-input-border-bottom-weight) solid white !important;
  color: white !important;
}

.input-container .ui.input.search-input > input:hover:not(:focus) {
  background-color: var(
    --search-field-input-default-mode-hovered-bgr-clr
  ) !important;
}

.input-container .ui.input.search-input > input:focus {
  background-color: var(
    --search-field-input-default-mode-focused-bgr-clr
  ) !important;
  border-bottom: var(--search-field-input-border-bottom-weight) solid black !important;
}

.footer-input-container .ui.input.search-input > input:hover:not(:focus) {
  background-color: var(
    --search-field-input-footer-mode-hovered-bgr-clr
  ) !important;
}

.footer-input-container .ui.input.search-input > input:focus {
  background-color: var(
    --search-field-input-footer-mode-focused-bgr-clr
  ) !important;
  border-bottom: var(--search-field-input-border-bottom-weight) solid white !important;
}

.search-field__buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: max-content;
  position: absolute;

  height: calc(
    100% - var(--search-field-input-border-bottom-weight)
  ) !important;
  top: 0;
  right: 0;
}

.search-field__buttons .input-button,
.footer-input-container .input-button {
  /*width: var(--search-field-button-width);*/
  width: 100%;
  z-index: var(--search-field-button-z-index);
  background-color: transparent !important;
  display: flex;
  align-items: center;

  height: 100%;
  padding: 0.6875em 0.8em;
  margin: 0;
}

.search-field__buttons .input-button svg,
.footer-input-container .input-button svg {
  width: var(--search-field-button-icon-size);
  height: var(--search-field-button-icon-size);
  opacity: 1;
}

.input-container .ui.button.input-button:hover,
.input-container .ui.button.input-button:focus {
  background-color: var(
    --search-field-button-default-mode-hovered-bgr-clr
  ) !important;
}

.footer-input-container .ui.button.input-button:hover,
.footer-input-container .ui.button.input-button:focus {
  background-color: var(
    --search-field-button-footer-mode-hovered-bgr-clr
  ) !important;
}

.search-result-background {
  background-color: #ffffff;
  box-shadow: 0 -16px 0 #fff, 0 0 16px rgb(0 0 0 / 25%);
  margin-top: 20px;
}

.search-result-background .search-result-container {
  /* position: absolute;
  top: 127%; */
  margin: 0 auto;
  padding: 10px 0;
  max-width: 1120px;
  /* max-height: 300px; */
  overflow: auto;
}

.search-result-background
  .search-result-container
  .search-suggestion-container
  .search-suggestion {
  padding: 12px 20px;
}

.search-result-background
  .search-result-container
  .search-suggestion-container
  .search-suggestion:hover {
  color: #005691;
  cursor: pointer;
}

.search-result-background .search-result-container .no-results {
  padding: 12px 20px 20px 20px;
  font-style: italic;
}

.search-result-background .search-result-container .all-results-suggestion {
  padding: 12px 20px 20px 20px;
  color: #007bc0;
}

.search-result-background
  .search-result-container
  .all-results-suggestion:hover {
  color: #005691;
  cursor: pointer;
}

.search-icon {
  margin-left: 2px !important;
  margin-right: 6px !important;
  height: auto !important;
}

.search-input * {
  font-family: var(--boschFont) !important;
}

.ui.button.input-button > i {
  height: auto !important;
  margin-left: 2px !important;
  margin-right: 6px !important;
}

.footer-input-container .ui.button.input-button > i {
  fill: var(--boschWhite);
}
