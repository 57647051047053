div.ui.grid.menu-header-items {
  margin: 0 0 0 30px;
}

div.ui.grid.menu-header-items div.column {
  padding: 0;
}

div.ui.grid.menu-header-items div.menu-header-items-layer2 {
  display: flex !important;
  flex-direction: column;
}

div.ui.grid.menu-header-items
  div.menu-header-items-layer2
  button.ui.basic.button {
  margin: 10px;
}
@media only screen and (max-width: 450px) {
  div.ui.grid.menu-header-items
    div.menu-header-items-layer2
    button.ui.basic.button {
    margin-left: -35px;
  }
}

div.ui.grid.menu-header-items button.ui.basic.button {
  box-shadow: none;
  text-align: left;
  color: #000 !important;
  padding-left: 0;
}

/* div.ui.grid.menu-header-items > div.column > div.row > button.ui.basic.button:hover,
div.ui.grid.menu-header-items > div.column > div.menu-header-items-layer2 > button.ui.basic.button:hover{
  color: #006EAD !important;
  text-decoration: underline;
} */

div.ui.grid.menu-header-items
  > div.column
  > div.row
  > button.ui.basic.button:focus {
  color: #006ead !important;
}

div.ui.grid.menu-header-items button.ui.basic.button:active {
  background: transparent !important;
}

div.row.menu-header-language-selector {
  display: flex;
  padding: 0.78571429em 1.5em 0.78571429em 0;
  align-items: center;
}

div.row.menu-header-language-selector div.dropdown {
  margin-left: 0.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.3rem;
  background: #e0e2e5;
}

div.row.menu-header-language-selector a {
  color: #000;
}

.menu-header-items-mobile-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu-header-items-mobile {
  width: 100%;
}

.menu-header-mobile-icons {
  display: flex;
}

.icon-close {
  padding-left: 0 !important;
}

.profile-mobile-icon {
  /* margin-left: 12rem !important;
  padding-left: 4rem !important; */
  position: absolute !important;
  left: 8rem;
}

.profile-mobile-icon > .menu {
  /* margin-left: 12rem !important;
  padding-left: 4rem !important; */
  position: absolute !important;
  right: -13rem !important;
}

.menu-header-items-layer2 {
  margin-left: 2rem;
}

.header_fontsize {
  font-size: var(--fs-60) !important;
}

@media only screen and (max-width: 991px) {
  .breadcumb-box-account-cart {
    display: none;
  }
}

.lang-selection-container {
  filter: drop-shadow(0 0 4px #00000040) !important;
  padding: var(--card-spacing-sm);
  background: var(--boschWhite) !important;
  margin-top: var(--card-spacing-sm) !important;

  border-radius: 0 !important;
}

.lang-selection-triangle {
  position: absolute;
  background: white;
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  transform: rotate(180deg) translate(50%, 100%);
  filter: drop-shadow(0 0 4px #00000040);

  left: 50% !important;
  width: 2.5rem;
  height: 1.5rem;
}

.lang-selection-wrapper {
  display: flex !important;
  flex-direction: column;
  padding: var(--card-spacing-sm);

  width: 15.625rem;
}

.lang-selection-toolbar {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: var(--text-s);
}

/* CSS for Close icon */
.lang-selection-toolbar > svg {
  cursor: pointer;

  width: 1.5rem;
  height: 1.5rem;
}

.lang-selection-toolbar > svg:hover {
  fill: var(--boschBlue50);
}

.lang-selection-label {
  margin-bottom: 0.625rem !important;
}

.lang-selection-find-local-website {
  color: var(--boschBlue50) !important;

  margin-top: 1.5rem;
}

.lang-selection-find-local-website:hover {
  text-decoration: underline;
}

@media (max-width: 1366px) {
  .lang-selection-triangle {
    left: 85% !important;
  }
}

@media (max-width: 1024px) {
  .lang-selection-triangle {
    left: 90% !important;
  }
}
