:root {
  --lang-selection-box-width: 15.625rem;
}

.rd_lang_selection_container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 8px 12px 14px !important;
  width: 46px;
}

.lang_button {
  box-shadow: none !important;
  font-size: var(--text-l) !important;
  padding: 0 !important;
  margin: 0 !important;
}

.lang_selection_box {
  position: absolute;
  filter: drop-shadow(0 0 4px #00000040);
  margin-top: var(--card-spacing-lg);
  width: var(--lang-selection-box-width);

  top: 100%;
  right: -0.25rem;
  z-index: 12;
}

.lang_selection_triangle {
  position: absolute;
  background: white;
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  transform: rotate(180deg) translate(50%, 100%);

  left: 92%;
  width: 2.5rem;
  height: 1.5rem;
}

.lang_selection_wrapper {
  display: flex;
  flex-direction: column;
  padding: var(--card-spacing-sm);
  background: var(--boschWhite);

  width: 100%;
}

.lang_selection_toolbar {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding-bottom: var(--text-s);
}

.close_lang_selection_icon {
  cursor: pointer;

  width: 1.5rem;
  height: 1.5rem;
}

.close_lang_selection_icon:hover {
  fill: var(--boschBlue50);
}

.lang_selection_label {
  margin-bottom: 0.625rem;
}

.lang_selection_find_local_website {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--boschBlue50);

  margin-top: 1.5rem;
}

.lang_selection_find_local_website > i {
  margin: 0;
}

.lang_selection_find_local_website:hover > span {
  text-decoration: underline;
}

@media screen and (min-width: 720px) {
  .lang_selection_box {
    margin-top: var(--card-spacing);
  }
}

@media screen and (min-width: 1550px) {
  .lang_selection_box {
    transform: translateX(-50%);
    left: 50%;
  }

  .lang_selection_triangle {
    left: 50%;
  }
}

@media (max-width: 991px) {
  #icon_desktop {
    display: none;
  }
}

@media screen and (max-width: 719px) {
  .lang_selection_box {
    margin-top: var(--card-spacing) !important;
  }
}
