.landing_banner{
    display: flex !important;
    flex-flow: row !important;
    align-items: baseline;
    width: 100%;
}

.group_coaching{
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    align-items: self-end;
    display: flex !important;
    padding-bottom: 0.5rem !important;
    flex-flow: row-reverse;
}

.group_coaching > svg{
    width: 70% !important; 
}

.portrait{
    width: 100% !important;
    padding-left: 0rem !important;
    padding-top: 0rem !important;
    padding-right: 3rem !important;
    padding-bottom: 0rem !important;
    align-items: self-end;
    display: flex !important;
    flex-flow: row-reverse;
}

.portrait > svg{
    width: 60.64% !important;
}


