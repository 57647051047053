@use "../../styles/responsive.module" as r;

.container {
  display: flex;
  flex-direction: column;

  width: 100%;

  & :global(.m-side-navigation__menuItem) {
    & button {
      background-color: transparent !important;

      &:hover {
        background-color: var(--boschGray30) !important;
      }
    }
  }
}

@include r.laptop_s_screen_and_up {
  .container {
    .hide_on_desktop {
      display: none !important;
    }
  }
}
