/*Start withdraw-program-modal*/
.withdraw-program-modal {
  display: flex;
  justify-content: center !important;
}

.withdraw-program-modal.warning > .modal {
  border-top: 6px solid var(--boschYellow85);
}

.withdraw-program-modal > .modal > .header {
  border: 0 !important;
  padding: 0 !important;
  padding-bottom: 0 !important;
  display: flex;
  align-items: flex-start;
  border-bottom: 0.0625rem solid var(--boschGray60) !important;
}


.withdraw-program-modal > .modal > .header > span {
  font-size: var(--text-m) !important;
  font-weight: bold;
  margin: 0.75rem 2rem 0.75rem 0.5rem;
  flex: 1;
}
.withdraw-program-modal > .modal > .header > i {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem !important;
  margin: 0.75rem 0.5rem 0.75rem 2rem !important;
}
.withdraw-program-modal > .modal > .header > .close_button_header {
  background-color: transparent !important;
}
.withdraw-program-modal > .modal > .header > .close_button_header > i {
  margin: 0 !important;
  font-size: 1.5rem !important;
}

.withdraw-program-modal > .modal > .content {
  padding: 1.5rem 2rem 0 2rem !important;
}
.withdraw-program-modal > .modal > .content > .ui.grid {
  margin: 0;
  font-size: var(--text-m) !important;
}
.withdraw-program-modal > .modal > .content > .ui.grid > .row {
  padding: 0 !important;
  margin-bottom: 1rem !important;
}
.content_header {
  font-size: var(--text-xl) !important;
  font-weight: bold !important;
}
.withdraw-program-modal > .modal > .content > .ui.grid ul {
  margin: 0 !important;
  padding-left: 1rem !important;
}
.withdraw-program-modal > .modal > .actions {
  border: 0 !important;
  padding: 1.5rem 2rem 2rem 2rem !important;
  background: transparent !important;
  gap: 1rem !important;
  display: flex !important;
  justify-content: flex-end;
  padding-top: 0 !important;
}

.withdraw-program-modal > .modal > .actions > button {
  min-width: 125px;
  font-weight: normal;
}

/*End withdraw-program-modal*/

/*Start withdraw-program-success-modal*/
.withdraw-program-success-modal {
  display: flex;
  justify-content: center !important;
}
.withdraw-program-success-modal > .modal > .header {
  border: 0 !important;
  padding-bottom: 0 !important;
}

.withdraw-program-success-modal > .modal > .actions {
  border: 0 !important;
  padding-top: 0 !important;
}

.withdraw-program-success-modal > .modal > .actions > button {
  min-width: 125px;
}
/*End withdraw-program-success-modal*/

/*Start withdraw-program-failed-modal*/
.withdraw-program-failed-modal {
  display: flex;
  justify-content: center !important;
}
.withdraw-program-failed-modal > .modal > .header {
  border: 0 !important;
  padding-bottom: 0 !important;
}

.withdraw-program-failed-modal > .modal > .actions {
  border: 0 !important;
  padding-top: 0 !important;
}

.withdraw-program-failed-modal > .modal > .actions > button {
  min-width: 125px;
}
/*End withdraw-program-failed-modal*/
