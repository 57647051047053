.button {
  display: inline-flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
  padding: var(--rd-button-padding) !important;
  gap: var(--card-responsive-spacing) !important;
  font-size: var(--text-m) !important;
  min-width: var(--rd-button-min-width) !important;
  background: none !important;
  transition: none !important;

  line-height: 1.5rem !important;
  min-height: 1rem !important;
  max-height: 3rem !important;
  letter-spacing: 0 !important;
  border: 0 !important;
  opacity: 1 !important;
  margin: 0 !important;
}

.button > p,
.button > span {
  font-weight: normal !important;

  margin: 0 !important;
  opacity: 1 !important;
}

.button > i {
  font-size: var(--text-xl) !important;

  margin: 0 !important;
  opacity: 1 !important;
}

.button > svg {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin: 0 !important;
  opacity: 1 !important;
}

.button.disabled {
  cursor: default;
  pointer-events: none !important;
}

/* PRIMARY BUTTON */
.primary {
  background: var(--boschBlue50) !important;
  color: var(--boschWhite) !important;
}

.primary > p,
.primary > span,
.primary > i,
.primary > svg {
  color: var(--boschWhite);
  fill: var(--boschWhite);
}

.primary:hover {
  background: var(--boschBlue40) !important;
}

.primary:active {
  background: var(--boschBlue30) !important;
}

.primary.disabled {
  background: var(--boschGray80) !important;
  color: var(--boschGray60) !important;
}

.primary.disabled > p,
.primary.disabled > span,
.primary.disabled > i,
.primary.disabled > svg {
  color: var(--boschGray60) !important;
  fill: var(--boschGray60) !important;
}

/* SECONDARY BUTTON */
.secondary {
  background: var(--boschWhite) !important;
  color: var(--boschBlue50) !important;
  border: 0.0625rem solid var(--boschBlue50) !important;
}

.secondary > p,
.secondary > span,
.secondary > i,
.secondary > svg {
  color: var(--boschBlue50);
  fill: var(--boschBlue50);
}

.secondary:hover {
  background: var(--boschBlue90) !important;
  color: var(--boschBlue40) !important;
  border-color: var(--boschBlue40) !important;
}

.secondary:hover > p,
.secondary:hover > span,
.secondary:hover > i,
.secondary:hover > svg {
  color: var(--boschBlue40);
  fill: var(--boschBlue40);
}

.secondary:active {
  background: var(--boschBlue80) !important;
  color: var(--boschBlue30) !important;
  border-color: var(--boschBlue30) !important;
}

.secondary:active > p,
.secondary:active > span,
.secondary:active > i,
.secondary:active > svg {
  color: var(--boschBlue30);
  fill: var(--boschBlue30);
}

.secondary.disabled {
  background: #0000 !important;
  color: var(--boschGray80) !important;
  border-color: var(--boschGray80) !important;
}

.secondary.disabled > p,
.secondary.disabled > span,
.secondary.disabled > i,
.secondary.disabled > svg {
  color: var(--boschGray80);
  fill: var(--boschGray80);
}

/* TERTIARY BUTTON */
.tertiary {
  background: #0000 !important;
  color: var(--boschBlue50) !important;
}

.tertiary > p,
.tertiary > span,
.tertiary > i,
.tertiary > svg {
  color: var(--boschBlue50);
  fill: var(--boschBlue50);
}

.tertiary:hover {
  background: var(--boschBlue90) !important;
  color: var(--boschBlue40) !important;
}

.tertiary:hover > p,
.tertiary:hover > span,
.tertiary:hover > i,
.tertiary:hover > svg {
  color: var(--boschBlue40);
  fill: var(--boschBlue40);
}

.tertiary:active {
  background: var(--boschBlue80) !important;
  color: var(--boschBlue30) !important;
}

.tertiary:active > p,
.tertiary:active > span,
.tertiary:active > i,
.tertiary:active > svg {
  color: var(--boschBlue30);
  fill: var(--boschBlue30);
}

.tertiary.disabled {
  background: #0000 !important;
  color: var(--boschGray80) !important;
}

.tertiary.disabled > p,
.tertiary.disabled > span,
.tertiary.disabled > i,
.tertiary.disabled > svg {
  color: var(--boschGray80);
  fill: var(--boschGray80);
}

/* INTEGRATED BUTTON */
.integrated {
  background: #0000 !important;
  color: var(--boschBlack) !important;
}

.integrated > p,
.integrated > span,
.integrated > i,
.integrated > svg {
  color: var(--boschBlack);
  fill: var(--boschBlack);
}

.integrated:hover {
  background: #0000 !important;
  color: var(--boschBlue50) !important;
}

.integrated:hover > p,
.integrated:hover > span,
.integrated:hover > i,
.integrated:hover > svg {
  color: var(--boschBlue50);
  fill: var(--boschBlue50);
}

.integrated:active {
  background: #0000 !important;
  color: var(--boschBlue40) !important;
}

.integrated:active > p,
.integrated:active > span,
.integrated:active > i,
.integrated:active > svg {
  color: var(--boschBlue40);
  fill: var(--boschBlue40);
}

.integrated.disabled {
  background: #0000 !important;
  color: var(--boschGray80) !important;
}

.integrated.disabled > p,
.integrated.disabled > span,
.integrated.disabled > i,
.integrated.disabled > svg {
  color: var(--boschGray80);
  fill: var(--boschGray80);
}

/* SMALL BUTTON */
.small {
  padding: var(--rd-button-small-padding) !important;
  min-width: var(--rd-button-small-min-width) !important;
  font-size: 0.8125rem !important; /* 0.8125rem = 13px = 0.75rem (--text-s) +  */
  line-height: 1.5625rem !important; /* 1.5625rem = 1.5rem (line-height of --text-s) + 0.0625 (0.8125 - 0.75) */
}

.small > i {
  font-size: var(--text-l) !important;
}

.small > svg {
  width: 1.25rem !important;
  height: 1.25rem !important;
}

/* SET MIN-WIDTH PROPERTY */
.fluid {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.maxContent {
  min-width: max-content !important;
}
