@font-face {
  font-family: "Bosch-Icon";
  src: url("./assets/fonts/Bosch-Icon.eot");
  src: url("./assets/fonts/Bosch-Icon.eot") format("embedded-opentype");
  src: url("./assets/fonts/Bosch-Icon.ttf") format("truetype");
  src: url("./assets/fonts/Bosch-Icon.woff") format("woff");
  src: url("./assets/fonts/Bosch-Icon.woff2") format("woff2");
  src: url("./assets/fonts/Bosch-Icon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
.Boschicon {
  font-family: Bosch-Icon !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button.ui.button {
  border-radius: 0 !important;
}

button.ui.button.primary-btn {
  background: #005691;
  color: #fff;
}

.ui.button.btn-green {
  background-color: var(--boschGreen50);
  color: var(--boschWhite);
}
.ui.button.btn-green:hover {
  background-color: var(--boschGreen50);
  color: var(--boschWhite);
}

/* Override font family to BOSCH*/
/* body {
  font-family: Bosch Sans, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  color: var(--boschBlack);
} */
/* Override font size*/

/*Text 5XL*/
h1 {
  font-size: 4rem !important;
  font-family: Bosch Sans, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

/*Text 4XL*/
h2 {
  font-size: 3rem !important;
  font-family: Bosch Sans, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

/*Text 3XL*/
h3 {
  font-size: 2.5rem !important;
  font-family: Bosch Sans, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

/*Text 2XL*/
h4 {
  font-size: 2rem !important;
  font-family: Bosch Sans, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

/*Text XL*/
h5 {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  font-family: Bosch Sans, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

body,
html {
  font-size: 16px !important;
}

/* body div {
  width: 100%;
} */
/* 
div.app-body {
  display: grid;
  grid-template-columns: minmax(16px, 1fr) minmax(750px, 1170px) minmax(16px, 1fr);
} */
div.app-body-container {
  padding: 0rem 1.875rem;
}

/* grid responsive */
.grid-responsive-width {
  width: calc(100% - 2rem);
  /* max-width: 1120px; */
  max-width: 1300px;
  margin: 0 1rem;
}

.grid-responsive-width.body-container.home-page-wrapper {
  max-width: var(--CDM3-max-width) !important;
  padding-top: 0;
}
.grid-responsive-width.body-container.checkout-page-wrapper {
  padding: 0 !important;
}
.grid-responsive-width > .ui.grid:first-child {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.grid-responsive-width:has(> .checkout-cart-wrapper) {
  padding-top: 0 !important;
}
@media only screen and (min-width: 1152px) {
  .grid-responsive-width {
    /* width: 100% !important; */
    margin: 0 auto !important;
  }
}
.body-container {
  padding-top: 2rem;
}

/* @media only screen and (min-width:768px) and (max-width:991px){
  .grid-responsive-width{
    width: calc(723px + 2rem) !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 0 1rem;
  }
}

@media only screen and (min-width:992px) and (max-width:1199px){
  .grid-responsive-width{
    width: calc(933px + 2rem) !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 0 1rem;
  }
}

@media only screen and (min-width:1200px){
  .grid-responsive-width{
    width: calc(1127px + 2rem) !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 0 1rem;
  }
} */

/* unordered list with green check as bullets */
ul.green-check-as-bullets-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 24px;
}

ul.green-check-as-bullets-ul li::before {
  content: "";
  display: inline-block;
  height: 1.4rem;
  width: 1.4rem;
  margin-right: 0.5rem;
  background-image: url("/src/assets/svg/checkmark-frame.svg");
  vertical-align: sub;
}

/*primary button*/
button.ui.button.bwc-btn--primary {
  background: var(--boschBlue50);
  color: #ffffff;
}

button.ui.button.bwc-btn--primary:active {
  background: #004975;
}
button.ui.button.bwc-btn--primary:hover {
  background: #00629a;
}

.bwc-btn--danger {
  background-color: #ed1c24 !important;
}

/* Filter */
.training-filter {
  width: calc(1000px + 64px);
}

/* Training header title container */
.ui.grid.container.training-title-container {
  margin: 0;
  width: 100% !important;
}

.ui.modal {
  border-radius: 0 !important;
}
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}
@media (min-width: 768px) {
  .body-container {
    padding-top: 2rem;
  }
}

/* .ui.grid.container{
  width: calc(1300px + 2rem) !important;
} */

#homepage-stories-content {
  /* width: calc(1700px +2rem) !important; */
  width: 100% !important;
}
.explore-wrapper {
  width: 100% !important;
}

.stories-headline-wrapper {
  width: 100% !important;
}

#stories-header-wrapper {
  width: 100% !important;
}

.custom-grid {
  width: 100% !important;
}

.our-services-and-get-in-touch-container {
  width: 100% !important;
}

.homepage-maintitle {
  display: none;
}

/*Start pagination*/
.r-pagination {
  border: none !important;
  box-shadow: none !important;
}

.r-pagination > .item::before {
  display: none;
}

.r-pagination > .item {
  border-radius: 50% !important;
  justify-content: center;
  margin-right: 0.25rem;
}
.r-pagination > .active.item,
.r-pagination > a.item.active:hover,
.r-pagination > a.item:focus-visible .r-pagination > a.item:active {
  background-color: var(--boschBlue50) !important;
  color: var(--boschWhite) !important;
}
.r-pagination > a.item:hover {
  background-color: var(--boschBlue40) !important;
  color: var(--boschWhite) !important;
}

.r-pagination.small > .item {
  width: 38px !important;
  height: 38px !important;
}
/*end pagination*/

/*Semantic customization*/
.ui.modal.active > .actions {
  padding-bottom: var(--card-spacing-sm) !important;
}
.ui.checkbox label,
.ui.checkbox + label,
.ui.menu .item,
.ui.table,
.ui.dropdown .menu > .item,
.ui.dropdown .menu .selected.item,
.ui.dropdown.selected,
.ui.card > .content > .header,
.ui.cards > .card > .content > .header {
  color: var(--boschBlack);
}
.ui.basic.button,
.ui.basic.buttons .button {
  color: var(--boschBlack) !important;
}

@media (max-width: 767px) {
  table.unstackable.flex-table th,
  table.unstackable.flex-table td {
    display: none;
  }
  table.unstackable.flex-table th.mobile,
  table.unstackable.flex-table td.mobile {
    display: table-cell;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  table.unstackable.flex-table th,
  table.unstackable.flex-table td {
    display: none;
  }
  table.unstackable.flex-table th.tablet,
  table.unstackable.flex-table td.tablet {
    display: table-cell;
  }
}
/*End semantic customization*/

/* To disable the iframe that created automatically by Create-react-app */

body > iframe {
  pointer-events: none;
}
