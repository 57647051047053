.test-dimmer.addtocart-elearningcoursemodal{
    justify-content: center !important;
}

/*Start addtocart-elearningcoursemodal*/
.addtocart-elearningcoursemodal{
    display: flex;
    justify-content: center !important;
}

.addtocart-elearningcoursemodal .modal{
    border-top: var(--modal-border-size) solid var(--boschGreen50);
}

.addtocart-elearningcoursemodal.warning > .modal{
    border-top: 6px solid rgb(255, 207, 0);
}

.addtocart-elearningcoursemodal.error > .modal{
    border-top: 6px solid rgb(237, 0, 7);
}

.addtocart-elearningcoursemodal.success > .modal{
    border-top: 6px solid #00884a;
}

.addtocart-elearningcoursemodal > .modal > .header{
    border: 0 !important;
    padding-bottom: 0 !important;
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
    font-size: var(--fs-70) !important;
}
.addtocart-elearningcoursemodal > .modal > .header > .close-icon{
    font-size: var(--fs-60);
    position: absolute;
    right: var(--card-spacing-sm);
    margin: 0 !important;
}
.addtocart-elearningcoursemodal > .modal > .header > div{
    display: flex;
    align-items: center;
}
.addtocart-elearningcoursemodal > .modal > .header > div > i{
    font-size: 1.25em;
}

.addtocart-elearningcoursemodal > .modal > .content > .ui.grid{
    margin: 0;
}

.addtocart-elearningcoursemodal > .modal > .actions{
    border: 0 !important;
    padding-top: 0 !important;
}

.addtocart-elearningcoursemodal > .modal > .actions > button{
    min-width: 125px;
    margin: 0 !important;
}

.align-right-add-event{
    text-align: right !important;
}

@media (max-width: 767px) {
    .addtocart-elearningcoursemodal > .modal > .header > .close-icon{
        right: var(--card-responsive-spacing);
    }
}