.price_tag_wrapper{
  white-space: nowrap !important;
  display: flex !important;
  flex-flow: column;
  align-items: end;
}

.list_price {
  font-size: var(--fs-80) !important;
  font-weight: normal !important;
  color: var(--boschGray50);
  text-decoration: line-through;
}

.price {
  color: var(--boschRed50);
}

.your_price{ 
  font-weight: bold !important;
}

.your_price_type{
  margin-left: 0.125rem;
  font-weight: normal !important;
}

.your_save {
  color: var(--boschGreen50);
  font-weight: normal !important;
  background: var(--boschTurquoise95);
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.price_vat{
  font-size: var(--fs-100) !important;
  font-weight: normal !important;
}