.maintenance_message{
    color: var(--boschBlue50);
    padding-left: 5rem;
    padding-right: 5rem;
    font-size: medium;
    font-weight: bold;
    margin-top: 0.5rem !important;
}
.info_message{
    margin-left: 3.45rem;
    padding-bottom:  1rem !important;
}
.maintenance_image {
    display: flex;
    flex-flow: column;
}
.maintenance_image > svg{
    height: 580px;
}

@media (max-width:767px){
    .maintenance_message{     
        padding: 0.5rem !important;
        font-size: medium;
        font-weight: bold;
    }

    .info_message{
        margin: 0 !important;
    }

    .maintenance_image > svg{
        height: auto !important;
        margin-top: 1rem !important;
    }    
}

@media only screen and (max-width: 820px) {
    .maintenance_message{     
        padding: 0.5rem !important;
        font-size: medium;
        font-weight: bold;
    }

    .maintenance_image > svg{
        height: 580px;
        margin-top: 1rem !important;
    }

    .info_message{
        margin-left: 0 !important;
        padding-bottom:  1rem !important;
    }
}

@media only screen and (max-width: 991px) {
    .maintenance_message{     
        padding: 0.5rem !important;
        font-size: medium;
        font-weight: bold;
    }

    .maintenance_image > svg{
        height: 580px;
        margin-top: 1rem !important;
    }

    .info_message{
        margin-left: 0 !important;
    }    
}







  