p.confirm-your-email {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.confirm-modal-intro {
     margin-bottom: 1.5rem;
}

.confirm-modal-description3 {
     margin-top: 1.5rem;
}