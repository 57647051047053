.button_with_icon {
  display: flex !important;
  align-items: center;
  text-align: center !important;
  justify-content: center;
  height: 3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin: 0 !important;
}

.button_with_icon > i {
  font-size: 1.5rem;
  margin: auto 0;
  display: flex;
  align-items: center;
}
