/*Start addtocartsuccess-modal-wrapper*/
.addtocartsuccess-modal-wrapper{
    display: flex;
    justify-content: center !important;
}

.addtocartsuccess-modal-wrapper.warning > .modal{
    border-top: 6px solid rgb(255, 207, 0);
}

.addtocartsuccess-modal-wrapper.error > .modal{
    border-top: 6px solid rgb(237, 0, 7);
}

.addtocartsuccess-modal-wrapper.success > .modal{
    border-top: 6px solid #00884a;
}

.addtocartsuccess-modal-wrapper > .modal > .header{
    border: 0 !important;
    padding-bottom: 0 !important;
    align-items: center;
    justify-content: space-between;
    display: flex;
    font-size: var(--fs-70) !important;
}

.addtocartsuccess-modal-wrapper > .modal > .header > div{
    display: flex;
    align-items: flex-start;
}
.addtocartsuccess-modal-wrapper > .modal > .header > div > i{
    font-size: var(--fs-60) !important;
    font-weight: bold !important;
}

.addtocartsuccess-modal-wrapper > .modal > .content > .ui.grid{
    margin: 0;
}

.addtocartsuccess-modal-wrapper > .modal > .actions{
    border: 0 !important;
    padding-top: 0 !important;
    display: flex;
    justify-content: flex-end;
    gap: var(--card-responsive-spacing);
}

.addtocartsuccess-modal-wrapper > .modal > .actions > button{
    min-width: 125px;
    margin: 0 !important;
}


.addtocartsuccess-modal-wrapper > .modal > .content table .ui.button {
    background-color: transparent;
    padding: 0;
    font-size: 1.6rem;
}

.align-right{
    text-align: right !important;
}

@media (max-width: 767px){
    .common-modal-multiple-actions.add-to-cart-success-buttons > button{
        width: 45%;
        height: 100%;
    }
}