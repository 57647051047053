.training_program_header{
    display: flex !important;
    justify-content: space-between;
    width: 100%;
    align-items: center;    
}

.dashboard_header{
    display: flex !important;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.dashboard_header > span > i{
    font-size: 3rem !important;
    display: flex !important;
    align-items: center !important;
    padding: 0 !important;
    margin: 0 !important;
}

/* .exit_proxy_btn{
    background: var(--boschRed50) !important;
    color: var(--boschWhite) !important;
    font-weight: normal !important;
    margin: 0 !important;
} */

.header_bar_button_with_icon{
    background: var(--boschBlue50) !important;
    color: var(--boschWhite) !important;
    font-weight: normal !important;
    display: flex !important;
    align-items: center;
    margin: 0 !important;
}

.evaluate_note{
    color: var(--boschRed50) !important;    
}

.header_bar_button_with_icon.with_draw_program_btn{
    background: var(--boschRed50) !important;
    color: var(--boschWhite) !important;
    font-weight: normal !important;
}

.header_bar_button_with_icon.with_draw_course_btn_disable{
    color: var(--boschWhite) !important;
    font-weight: normal !important;
}


.header_bar{
    padding: 1rem !important;
    width: 100%;
}

@media (max-width: 767px) {
    .training_program_header{
        flex-direction: column !important;
        gap: var(--card-responsive-spacing);
    }
    .training_program_header .with_draw_program_btn{
        margin: 0 !important;
        width: 100% !important;
        justify-content: center !important;
    }
    .training_program_header .with_draw_program_btn > i{
        display: none !important;
    }
}