.container {
  & .trigger_button {
    & > i:last-child {
      opacity: 1;
      transition-duration: 0.5s;

      &.arrow_up {
        transform: rotate(-180deg);
      }

      &.arrow_hidden {
        opacity: 0;
      }
    }
  }

  & .sub_items {
    display: block !important;
    visibility: hidden;
    width: 100%;
    transition: 0.5s;
    opacity: 0;

    & > li {
      visibility: hidden !important;
      transition: 0.5s;
      opacity: 0 !important;
    }

    &.sub_items_open {
      visibility: visible;
      opacity: 1;

      & > li {
        visibility: visible !important;
        opacity: 1 !important;
      }
    }
  }
}
