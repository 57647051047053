.container {
  font-size: var(--text-m) !important;

  & .link {
    cursor: pointer;

    & .icon {
      font-size: 1.5rem !important;
    }
  }

  & > li:last-child {
    cursor: text;
    pointer-events: none;
  }
}
