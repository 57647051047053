$maintenance_banner_z_idx: 999;

$lang_selection_box_z_idx: 12;

$ads_us_search_by_plate_wrapper_z_idx: 100;
$ads_us_search_by_plate_z_idx: 1;

$date_picker_z_index: 100;

$new_request_selected_country_flag_z_idx: 1;

$minimal_header_z_index: 9;
$minimal_header_z_index: 100;
