.container {
  position: relative;

  & .cart_quantity {
    position: absolute;
    transform: translate(-50%, 25%);
    pointer-events: none;

    top: 0;
    left: 2.3rem;
  }
}
