@use "../styles/responsive.module" as r;
@use "../styles/dimension.module" as d;

.grid-responsive-width-CDM3 .grid-responsive-width {
  max-width: d.$base_screen_max_width;
}

.grid-responsive-width-CDM3
  .grid-responsive-width
  .home-video
  > .video-wrapper
  > video {
  width: 100%;
  margin-left: 0%;
}

.grid-responsive-width-CDM3 .grid-responsive-width .landing-image .home-img {
  left: -5% !important;
}

.body-container.body-container-cdm3 {
  background-color: var(--boschWhite);
}

.body-container-cdm3-full-width {
  background-color: var(--boschWhite) !important;
  padding: 0 !important;
}

@media (max-width: 991px) {
  .body-container.body-container-cdm3 {
    margin: var(--card-responsive-spacing) auto !important;
  }
}
@media (max-width: 767px) {
  .grid-responsive-width-CDM3 .grid-responsive-width .landing-image .home-img {
    left: 0% !important;
    width: 100%;
  }
}

@media (min-width: 1400px) {
  .grid-responsive-width-CDM3
    .grid-responsive-width
    .home-video
    > .video-wrapper
    > video {
    width: 108%;
    margin-left: -8%;
  }
}

@include r.bosch_ads_625x_screen_and_up {
  .grid-responsive-width-CDM3 .grid-responsive-width {
    max-width: d.$bosch_ads_625x_screen_max_width;
  }
}

@include r.desktop_l_screen_and_up {
  .grid-responsive-width-CDM3 .grid-responsive-width {
    max-width: d.$base_screen_max_width;
  }
}
