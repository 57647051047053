.mesagemodal-dimar .modal{
    border-top: var(--modal-border-size) solid var(--boschYellow85);
}

.evaluation-restriction-modal .modal{
    border-top: var(--modal-border-size) solid var(--boschYellow85);
}

.evaluation-restriction-modal{
  padding: 0 !important;
}

.evaluation-restriction-modal .header{
    display: flex !important;
    justify-content: start !important;
}

.evaluation-restriction-modal-content{
    padding-top: 0.5rem !important;
}

.evaluation-restriction-modal-content > .grid{
    margin: 0px !important;
}

.evaluation-restriction-modal-content .close-button{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 0px;
    margin-bottom: 0.2rem;
    margin-left: 0px;
    margin-right: 0px;
}

.evaluation-wrapper {
    padding-top: 0.5rem !important;
    margin: 0px !important;
  }
  
  .evaluation-wrapper > .annotations > .notice > p {
    padding-right: 1rem;
  }
  .evaluation-wrapper .notice {
    display: flex;
    flex-wrap: wrap;
  }
  
  .evaluation-wrapper > .grid {
    margin: 0px !important;
  }
  
  .evaluation-wrapper .cell-text-align-center {
    text-align: center !important;
  }
  
  .evaluation-wrapper .b-none {
    border: none !important;
  }
  
  .evaluation-wrapper .text-are-custom {
    width: 100%;
    background: #f5f5f5;
    padding: 0.5rem 0.7rem;
    margin-bottom: 0.5rem !important;
  }
  
  .evaluation-wrapper .radio-table-header {
    border: none !important;
    background: none !important;
    font-weight: normal !important;
  }
  
  .evaluation-wrapper .align-center {
    text-align: center !important;
  }
  
  .evaluation-wrapper .ui.table > thead > tr > * {
    border: none;
  }
  
  .evaluation-wrapper .ui.table.radio-table-header > thead > tr > *,
  .evaluation-wrapper .ui.table.radio-table > thead > tr > * {
    border: none !important;
  }
  
  .evaluation-wrapper .non-padding {
    padding: 0px !important;
  }
  
  .evaluation-wrapper .text-question {
    padding-top: 0.5rem !important;
  }
  

  .evaluation-wrapper .button-submit-custom {
    margin: 0 !important;
  }
  
  .evaluation-wrapper .ui.table.radio-table-header > tbody > tr > .pbottom-none,
  .evaluation-wrapper .ui.table.radio-table-header > tbody > thead > tr > .pbottom-none {
    padding-bottom: 0 !important;
  }
  
  .evaluation-wrapper .ui.table.radio-table > tbody > tr > td:first-child {
    padding-left: 0.78571429em !important;
  }
  
  .scrolling.dimmable>.dimmer.mesagemodal-dimar{
    justify-content: center !important;
  }

  .close_button{
    background: none !important;
    border: none !important;
    font-size: 2rem !important;
    padding: 0 !important;
  }

  @media (max-width: 767px) {
    .evaluation-wrapper .ui.table.radio-table-header > tbody > tr,
    .evaluation-wrapper .ui.table.radio-table > tbody > tr {
      box-shadow: none !important;
    }
  
    .evaluation-wrapper .ui.table.radio-table > tbody > tr > td {
      display: table-cell !important;
      text-align: center !important;
    }
  
    .evaluation-wrapper .col-direction {
      display: flex !important;
      flex-direction: column !important;
      border: none !important; 
    }
  
    .evaluation-wrapper .ui.table.radio-table-header > tbody > tr > .non-padding {
      padding: 0 !important;
      font-weight: normal !important;
    }
  
    .evaluation-wrapper .annotations > .notice {
      display: block;
    }
  }