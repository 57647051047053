.assign_button.ui.button {
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  width: fit-content !important;
}

.assign_button.ui.button > i {
  color: var(--boschBlue50) !important;
  margin: 0 !important;
  font-size: var(--fs-30) !important;
  display: flex !important;
  align-items: center !important;
  width: fit-content !important;
}

.assign_button_header.ui.button {
/*   background-color: var(--boschBlue50) !important;
  color: var(--boschWhite) !important; */
  padding: var(--card-spacing-vertical) !important;
  margin: 0 !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 0.5rem !important;
  justify-content: center !important;
}

.assign_button_header.ui.button > i {
  color: var(--boschWhite) !important;
  margin: 0 !important;
   font-size: var(--fs-40) !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  width: fit-content !important;
}
