@use "../../styles/responsive.module" as r;

.container {
  overflow: visible !important;
  transition: 0.5s;

  &::-webkit-scrollbar {
    display: none !important;
  }

  &.open {
    :global(.m-side-navigation__header__label) {
      opacity: 1;
      padding: 0.5rem 1.2rem 0.5rem 1rem;
    }

    :global(.m-side-navigation__header__trigger) {
      transition: 0.5s;

      &:hover,
      &:active {
        background-color: var(--boschGray20);
      }

      & > i {
        color: white;
      }
    }
  }

  & :global(.m-side-navigation__header__label) {
    opacity: 0;
    padding: 0;
    transition: 0.5s;
  }

  & :global(.m-side-navigation__header__trigger) {
    &:hover,
    &:active {
      background-color: transparent;
    }

    & > i {
      color: var(--boschBlack);
    }
  }

  & :global(.m-side-navigation__menuItems) {
    height: calc(100vh - 0.375rem - 3rem);
    overflow-y: auto;
  }

  & .pin_button {
    display: none;
  }

  .wrapper {
    padding-bottom: var(--card-spacing-sm);

    flex: 1 1 auto;

    &::-webkit-scrollbar {
      display: none !important;
    }

    & i {
      pointer-events: none !important;
    }
  }
}

@include r.laptop_s_screen_and_up {
  .container {
    &.open {
      & :global(.m-side-navigation__header__trigger) {
        opacity: 0;
        visibility: hidden;

        &:hover {
          background-color: var(--boschGray40);
        }
      }
    }

    & :global(.m-side-navigation__header__trigger) {
      display: none;

      &:hover {
        background-color: var(--boschGray40);
      }

      &:active {
        background-color: var(--boschGray20);
      }

      & > i {
        color: var(--boschWhite);
      }
    }

    & :global(.m-side-navigation__menuItems) {
      overflow: unset;
    }

    & .pin_button {
      display: flex !important;
      position: absolute;
      visibility: hidden !important;

      top: 0.5rem;
      right: 0;
      opacity: 0 !important;
      transition: 0.5s;

      &.pin_button_shown {
        visibility: visible !important;
        opacity: 1 !important;
      }
    }

    .wrapper {
      overflow-y: auto;
      height: 60vh;
    }
  }
}
