.locations {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.locations i {
  display: flex;
  align-items: center;
  min-width: 1.18em !important;
  color: var(--boschBlue50) !important;
}

.locations span {
  font-weight: bold !important;
  color: var(--boschBlue50) !important;
}

.locations i svg {
  fill: var(--boschBlue50);
}

#header_level2_col {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh !important;
  width: 100vh !important;
  background: white;
  margin-left: var(--card-spacing);
  margin-top: var(--card-spacing);
}

#button_override {
  box-shadow: none;
  color: var(--boschBlack) !important;
}

#button_override:hover {
  color: var(--boschBlue50) !important;
  text-decoration: underline;
}

.header_level2 {
  position: fixed !important;
  top: 0;
  left: 0;
  margin-top: 4rem !important;
}

#header_level2_col_title {
  box-shadow: none;
  font-weight: bold;
  margin-bottom: 2rem;
  color: var(--boschBlack) !important;
}

.buttons_link {
  display: flex !important;
  flex-direction: row-reverse;
  align-items: center;
}

.buttons_link:hover :not(i) {
  color: var(--boschBlue50) !important;
  text-decoration: underline;
}

.buttons_mul_level {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  padding-right: 0 !important;
}

.buttons_mul_level:hover :not(i) {
  color: var(--boschBlue50) !important;
  text-decoration: underline;
}

@media (min-width: 991px) {
  #header_level2_col {
    display: none !important;
  }
}
