@use "./fontSizes.module" as fs;
@use "./responsive.module" as r;

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > #root {
  overflow: hidden;
}
body > #root:has(.add_on_card) {
  overflow: visible !important;
}

/* To disable the iframe that created automatically by Create-react-app */
body > iframe {
  pointer-events: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.headline {
  font-size: 28px !important;
  margin-bottom: 0.5rem !important;
  text-align: left !important;
}

.headline-description {
  font-size: 1rem !important;
  font-weight: 500;
}

.headline-subtitle {
  font-size: 2rem !important;
  text-align: left !important;
}

.acticle-title {
  font-size: 1.25rem !important;
  margin-bottom: 12px !important;
  line-height: 1.2 !important;
  font-weight: 700 !important;
}

.ui.divider {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.back-button {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 1.25rem !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}
.m-0 {
  margin: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mr-0 {
  margin-right: 0px !important;
}

.main-headline {
  font-size: 2rem !important;
}

.radio-border-checked > label::before {
  border-color: rgba(34, 36, 38, 0.35) !important;
}

.slide-down-animation {
  -webkit-transform: translateY(-100%) !important;
  transform: translateY(-100%) !important;
  transition: transform 250ms !important;
  transition: transform 250ms, -webkit-transform 250ms !important;
  z-index: 0 !important;
}

.slide-down-animation.visible {
  -webkit-transform: translateY(0) !important;
  transform: translateY(0) !important;
}

.black-dimmer,
.test-dimmer {
  background-color: rgba(0, 0, 0, 0.85) !important;
}

#root
  > div
  > div.grid-responsive-width.footer-cards
  > div
  > div.row.supergraphic-outner {
  flex-direction: column !important;
}

.text_oversize_handler {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  -webkit-line-clamp: 3; /* Overwrite this if you want */
  line-clamp: 3; /* Overwrite this if you want */
}

/* Custom Echart tooltip to follow on Bosch style*/
/* Insert className below to settings of tooltip */
/* Ex: tooltip: { className: "chart_tooltip" } */
.echart_tooltip {
  box-shadow: none !important;
  padding: 0 !important;
  width: fit-content !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  border: none !important;
}

button {
  line-height: 1.5 !important;
}

@include r.tablet_screen_and_up {
  .main-headline {
    font-size: 64px !important;
  }

  .headline {
    font-size: 36px !important;
  }

  .headline-subtitle {
    font-size: 36px !important;
  }

  .acticle-title {
    font-size: 1.25rem;
    margin-bottom: 12px;
    line-height: 1.2;
  }

  .headline-description {
    font-size: 1.25rem !important;
  }

  .back-button {
    font-size: 2rem;
  }
}

@include r.bosch_ads_625x_screen_and_up {
  body,
  html {
    font-size: fs.$bosch_ads_625x_body_font_size !important;
  }
}

@include r.desktop_l_screen_and_up {
  body,
  html {
    font-size: fs.$base_body_font_size !important;
  }
}
